import './categorias.css'
import './categorias.responsive.css'
import { categorias } from '../../helpers/CategoriasRecursos'

const Categorias = ({ setCategoria }) => {
	return (
		<div id="categorias">
			{
				categorias.map(categoria => (
					<button key={categoria} onClick={(e) => setCategoria(e.target.innerHTML)}>
						<h3>{categoria}</h3>
					</button>
				))
			}
		</div>
	)

}

export default Categorias