import Modal from "../Modal/Modal"
import Input from "../Input"
import { Formik, Form } from "formik"


const ModalModificarPagina = ({ modal, setModal, acceso, modificar }) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Modificar Página"
			idFormulario="modificar_pagina"
			textoBoton="Modificar"
		>
			<Formik
				initialValues={{
					pagina: acceso.pagina
				}}
				onSubmit={valores => modificar(valores, acceso.id)}
			>
				<Form id='modificar_pagina'>
					<Input
						nombre="pagina"
						etiqueta="Página:*"
						placeholder="URL de la Página"
						required
					/>
				</Form>
			</Formik>
		</Modal>
	)

}

export default ModalModificarPagina