import { FieldArray, Form, Formik } from "formik"
import { Calendar } from "react-calendar"
import Input from "../Input"
import Boton from "../Boton/Boton"
import { dateAFechaEspañol } from "../../helpers/Formato"
import { diaSemana } from "../../helpers/Variables"
import useVacaciones from "../../hooks/useVacaciones"
import usePaginacion from "../../hooks/usePaginacion"
import Notificacion from "../../helpers/Notificacion"
import Cargando from "../Cargando"
import Table from "../Table"
import { useEffect, useRef } from "react"
import { addDays, compareAsc, isSameMonth, isWeekend } from "date-fns"

const VacacionesPersonales = () => {

	const {
		solicitudes: { cargandoSolicitudes, solicitudes },
		getSolicitudesUsuario,
		crearSolicitud,
		cancelarSolicitud,
		disponibles,
		getDisponibles,
		setDisponibles,
		getSingleUser,
		user: { cargandoUser, user }
	} = useVacaciones()
	const [pagina, setPagina, porPagina] = usePaginacion(4)
	const filasVacias = pagina > 0 ? Math.max(0, (1 + pagina) * porPagina - solicitudes.length) : 0
	const formulario = useRef()
	const token = localStorage.getItem('token');
	const tokenPayload = JSON.parse(atob(token.split('.')[1]));
	const userId = tokenPayload.id;

	useEffect(() => {
		getDisponibles()
		getSingleUser(userId)
		getSolicitudesUsuario()
		// eslint-disable-next-line
	}, [])

	const crear = valores => {
		const idNotificacion = Notificacion.cargando('Solicitando Vacaciones...')
		crearSolicitud(valores)
			.then(resultado => {
				if (resultado === 1) {
					Notificacion.exito(idNotificacion, 'Solicitud Creada')
					getSolicitudesUsuario()
					formulario.current.values.fechas = []
				} else
					Notificacion.error(idNotificacion, 'Ocurrió un Error')
				getDisponibles()
			})
			.catch(err => console.error(err))
	}

	const cancelar = id => {
		const idNotificacion = Notificacion.cargando('Cancelando Solicitud...')
		cancelarSolicitud(id)
			.then(resultado => {
				if (resultado === 1) {
					Notificacion.exito(idNotificacion, 'Solicitud Cancelada')
					getSolicitudesUsuario()
				} else
					Notificacion.error(idNotificacion, 'Ocurrió un Error')

				getDisponibles()
			})
			.catch(err => console.error(err))
	}


	return (
		<div>
			<div>
				<h3>Solicitar Vacaciones</h3>
				<h4>Días Disponibles: {disponibles}</h4>
				<Formik
					initialValues={{ fechas: [] }}
					onSubmit={crear}
					innerRef={formulario}
				>
					{({ values }) => (
						<Form>
							<FieldArray name="fechas">
								{({ remove, push }) => (
									<div id='solicitar_vacaciones'>
										{!cargandoSolicitudes && !cargandoUser && (
											<Calendar
												//minDate={new Date()}
												locale="es-ES"
												onClickDay={value => {
													console.log('clicked')
													if (!values.fechas.some(fecha => fecha.fecha === value.toISOString().slice(0, 10)) && disponibles > 0) {
														push({
															fecha: value.toISOString().slice(0, 10),
															mostrar: `${diaSemana[value.getDay()]}, ${dateAFechaEspañol(value)}`
														})
														setDisponibles(disponibles - 1)
													} else {
														const fechaIndex = values.fechas.findIndex(fecha => fecha.fecha === value.toISOString().slice(0, 10));
														if (fechaIndex !== -1) {
															// Usando el método splice para eliminar un elemento del array.
															values.fechas.splice(fechaIndex, 1);
															setDisponibles(disponibles + 1);
														}
													}
												}}
												tileClassName={({ date, view, activeStartDate }) => {
													if (view !== 'month') return

													if (!isSameMonth(date, activeStartDate)) return

													if (solicitudes.some(solicitud => solicitud.fechas.split(';').some(fecha => fecha.trim() === date.toISOString().slice(0, 10)) && solicitud.aceptada === 1 && solicitud.puesto === user.puesto)) return 'aceptada'

													if (solicitudes.some(solicitud => solicitud.fechas.split(';').some(fecha => fecha.trim() === date.toISOString().slice(0, 10)) && solicitud.aceptada === 0 && solicitud.puesto === user.puesto)) return 'solicitada'

													return `${values.fechas.some(fecha => fecha.fecha === date.toISOString().slice(0, 10)) ? 'festivo' : ''}`;
												}}
												tileDisabled={({ date, view }) => {
													if (view !== 'month') return false

													// Deshabilitar fines de semana
													if (isWeekend(date)) return true

													// Deshabilitar el mismo dia a la fecha actual
													if (compareAsc(date, addDays(new Date(), 0)) === -1)
														return true

													return solicitudes.some(solicitud => solicitud.fechas.split(';').some(fecha => fecha.trim() === date.toISOString().slice(0, 10)) && solicitud.puesto === user.puesto)
												}}
											/>
										)}
										<div id="formulario_vacaciones">
											{values.fechas.map((_, i) => (
												<div className="busqueda" key={i}>
													<Input
														nombre={`fechas.${i}.mostrar`}
														etiqueta={`Fecha ${i + 1}:`}
														lectura
													/>
													<Boton
														texto="Eliminar"
														pequeño
														accion={() => {
															remove(i)
															setDisponibles(disponibles + 1)
														}}
													/>
												</div>
											))}
										</div>
									</div>
								)}
							</FieldArray>
							{
								values.fechas.length > 0 ?
									< Boton
										formulario
										texto="Solicitar"
										noFocus
										estilo={{ margin: '0 auto' }}
									/>
									: null
							}
						</Form>
					)}
				</Formik>
			</div>

			<div>
				<h3>Solicitudes de Vacaciones Personales</h3>
				<Table
					encabezado={['Días', 'Estado', 'Acciones', 'Motivo']}
					porPagina={porPagina}
					pagina={pagina}
					setPagina={setPagina}
					total={solicitudes.length}
					filasVacias={filasVacias}
					noFixed
				>

					{
						cargandoSolicitudes || cargandoUser ? <Cargando tabla />
							: (solicitudes.slice(pagina * porPagina, pagina * porPagina + porPagina)).map(solicitud => (
								<tr key={solicitud.id_solicitud_vacaciones}>
									<td>
										{
											solicitud.fechas.split(';').map((fecha, i) => (
												<p key={i}>{`${diaSemana[(new Date(`${fecha} 00:00:00`)).getDay()]}, ${dateAFechaEspañol(fecha)}`}</p>
											))
										}
									</td>
									<td>
										{solicitud.aceptada
											? 'ACEPTADO'
											: !solicitud.aceptada && solicitud.razon_rechazo !== null
												? 'RECHAZADO'
												: 'SOLICITADO'
										}
									</td>
									<td>
										{
											!solicitud.aceptada && !solicitud.razon_rechazo ?
												<Boton texto="Cancelar" pequeño accion={() => cancelar(solicitud.id_solicitud_vacaciones)} noFocus />
												: null
										}
									</td>
									<td>{solicitud.razon_rechazo}</td>
								</tr>
							))
					}
				</Table>
			</div>
		</div>
	)

}

export default VacacionesPersonales