import { useState } from "react"
import { cerrarSesion } from "../helpers/Variables"

const useServicios = () => {

	const [servicios, setServicios] = useState({ cargandoServicios: true, servicios: [] })

	// Obtener todos los servicios
	const getServicios = () => {
		fetch(`${process.env.REACT_APP_URI}/accesosPlataformas`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
			.then(response => {
				if (response.status === 401)
					cerrarSesion()
				return response.json()
			})
			.then(respuesta => setServicios({ cargandoServicios: false, servicios: respuesta }))
			.catch(err => console.error(err))
	}

	// Buscar servicios con filtros
	const buscarServicios = datos => {
		fetch(`${process.env.REACT_APP_URI}/accesosPlataformas?busqueda=${datos.enlace}`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
			.then(response => {
				if (response.status === 401)
					cerrarSesion()
				return response.json()
			})
			.then(respuesta => setServicios({ cargandoServicios: false, servicios: respuesta }))
			.catch(err => console.error(err))
	}

	// Crear un nuevo servicio
	const crearServicio = async datos => {
		const response = await fetch(`${process.env.REACT_APP_URI}/accesosPlataformas/crear`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
				'accept': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({
				enlace: datos.enlace.trim(),
				usuario: datos.usuario.trim() === '' ? null : datos.usuario.trim(),
				contraseña: datos.contraseña.trim() === '' ? null : datos.contraseña.trim(),
			})
		})
		if (response.status === 401)
			cerrarSesion()
		return await response.json()
	}

	// Modificar un anuncio
	const modificarServicio = async (datos, id) => {
		const response = await fetch(`${process.env.REACT_APP_URI}/accesosPlataformas/modificar/${id}`, {
			method: 'PUT',
			headers: {
				'content-type': 'application/json',
				'accept': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({
				enlace: datos.enlace.trim(),
				usuario: datos.usuario.trim() === '' ? null : datos.usuario.trim(),
				contraseña: datos.contraseña.trim() === '' ? null : datos.contraseña.trim(),
			})
		})
		if (response.status === 401)
			cerrarSesion()
		return await response.json()
	}

	return [servicios, getServicios, buscarServicios, crearServicio, modificarServicio]

}

export default useServicios