import "./vacaciones.css"
import Encabezado from "../Encabezado"
import VacacionesPersonales from "./VacacionesPersonales"
import { ToastContainer } from "react-toastify"
import { useNavigate } from "react-router-dom";
import Boton from "../Boton/Boton"


const VacacionesForAdmin = ({ title, encabezado, frase, fondo }) => {
	document.title = title
    const navigate = useNavigate();

    function mapView (){
		navigate("/vacaciones");
	}

	return (
		<div className="layout-contenido">

			<img className="layout-fondo" src={fondo} alt="fondo" />

			<Encabezado titulo={encabezado} frase={frase} />

			<section id="vacaciones">

                <Boton texto="Volver" accion={() => mapView()} noFocus />

				<VacacionesPersonales />

				<ToastContainer />

			</section >

		</div >
	)

}

export default VacacionesForAdmin