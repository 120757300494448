const ServiciosIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1rem"
		height="1rem"
		fill="none"
		viewBox="0 0 26 20"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.278 0h19.444c.737 0 1.444.263 1.964.732.521.47.814 1.105.814 1.768v15c0 1.375-1.25 2.5-2.778 2.5H3.278a2.942 2.942 0 0 1-1.964-.732C.793 18.798.5 18.163.5 17.5v-15c0-.663.293-1.299.814-1.768A2.942 2.942 0 0 1 3.278 0Zm0 17.5h19.444V5H3.278v12.5ZM4.629 3.896a1.331 1.331 0 1 0 0-2.662 1.331 1.331 0 0 0 0 2.662Z"
			fill="#999"
		/>
	</svg>
)

export default ServiciosIcon