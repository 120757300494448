import Input from "../Input"
import Modal from "../Modal/Modal"
import TextArea from "../TextArea"
import { Formik, Form } from "formik"
import Select from "../Select"
import { categorias } from "../../helpers/CategoriasRecursos"

const ModalModificarRecurso = ({ modal, setModal, modificar, recurso, eliminar }) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Modificar Recurso"
			idFormulario="modificar_recurso"
			textoBoton="Modificar"
			textoBotonTitulo="Eliminar"
			accionTitulo={() => eliminar(recurso.idRecurso)}
		>
			<Formik
				initialValues={{
					titulo: recurso.titulo,
					descripcion: recurso.descripcion || '',
					enlace: recurso.enlace,
					imagen: recurso.imagen || '',
					categoria: recurso.categoria
				}}
				onSubmit={datos => modificar(datos, recurso.idRecurso)}
			>
				<Form id="modificar_recurso">
					<Select
						nombre="categoria"
						etiqueta="Categoria:*"
					>
						{
							categorias.map(categoria => (
								<option value={categoria} key={categoria}>{categoria}</option>
							))
						}
					</Select>
					<Input
						nombre="titulo"
						placeholder="Título del Recurso"
						etiqueta="Título:*"
						required
					/>
					<TextArea
						nombre="descripcion"
						etiqueta="Descripción"
						placeholder="Descripción de la Recurso"
					/>
					<Input
						nombre="imagen"
						placeholder="URL de la Imagen del Recurso"
						etiqueta="Imagen:"
					/>
					<Input
						nombre="enlace"
						placeholder="Enlace del Recurso"
						etiqueta="Enlace:*"
						required
						tipo="url"
					/>
				</Form>
			</Formik>
		</Modal>
	)

}

export default ModalModificarRecurso