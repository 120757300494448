import { Field } from "formik"

const TextArea = ({ nombre, etiqueta, placeholder }) => {

	return (
		<div className="text-area">
			<label>{etiqueta}</label>
			<span>
				<Field as="textarea" name={nombre} placeholder={placeholder} />
			</span>
		</div>
	)

}

export default TextArea