import Modal from "../Modal/Modal"
import { Formik, Form } from "formik"
import Input from "../Input"


const ModalModificarAbono = ({ modal, setModal, modificar, saldo, abono }) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Modificar Abono"
			idFormulario="modificar_abono"
			textoBoton="Modificar"
		>
			<Formik
				initialValues={abono}
				onSubmit={modificar}
			>
				<Form id="modificar_abono">
					<Input
						nombre="abono"
						placeholder="Nueva cantidad del abono"
						etiqueta="Monto:*"
						tipo="number"
						required
						min="0"
						max={saldo}
						step="0.01"
					/>
					<Input
						nombre="fecha"
						etiqueta="Fecha:*"
						tipo="date"
						required
					/>
				</Form>
			</Formik>

		</Modal>
	)

}

export default ModalModificarAbono