const CorreosIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="0 0 21 21"
		{...props}
	>
		<path
			d="M10.64 4.65c1.59 0 3.09.706 4.19 1.81v.002c0-.53.356-.93.848-.93h.125c.778 0 .933.733.933.964l.004 8.232c-.054.539.557.818.896.471 1.32-1.357 2.9-6.983-.821-10.24-3.471-3.04-8.13-2.538-10.607-.831-2.632 1.819-4.315 5.836-2.68 9.612 1.784 4.117 6.887 5.344 9.924 4.12 1.538-.621 2.247 1.454.649 2.134-2.409 1.027-9.12.922-12.256-4.506C-.273 11.82-.16 5.37 5.46 2.028 9.754-.53 15.42.178 18.837 3.745c3.57 3.732 3.364 10.715-.122 13.43-1.578 1.235-3.922.034-3.905-1.765l-.018-.586a5.87 5.87 0 0 1-4.153 1.727c-3.148 0-5.92-2.773-5.92-5.918 0-3.179 2.772-5.98 5.92-5.98V4.65Zm3.96 5.756c-.12-2.304-1.83-3.692-3.896-3.692h-.078c-2.382 0-3.705 1.876-3.705 4.003 0 2.385 1.598 3.891 3.695 3.891 2.34 0 3.877-1.712 3.99-3.738l-.006-.464Z"
			fill="#999"
		/>
	</svg>
)

export default CorreosIcon