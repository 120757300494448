const BurgerIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="0 0 20 20"
		{...props}
	>
		<path
			d="M1.914 16.16h16.172M1.914 10h16.172M1.914 3.84h16.172"
			stroke="#999"
			strokeWidth={3}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default BurgerIcon