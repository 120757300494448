import Input from "../Input"
import Modal from "../Modal/Modal"
import { Formik, Form } from "formik"

const ModalModificarCorreo = ({ modal, setModal, correo, modificar }) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Modificar Correo"
			idFormulario="modificar_correo"
			textoBoton="Modificar"
		>
			<Formik
				initialValues={{
					nombre: correo.nombrePersonal || '',
					correo: correo.correo,
					contraseña: correo.contraseña
				}}
				onSubmit={valores => modificar(valores, correo.idCorreoInterno)}
			>
				<Form id='modificar_correo'>
					<Input
						nombre="nombre"
						etiqueta="Nombre Personal:"
						placeholder="Nombre de la Persona"
					/>
					<Input
						nombre="correo"
						etiqueta="Correo:*"
						placeholder="Correo Utilizado en WordPress"
						required
					/>
					<Input
						nombre="contraseña"
						etiqueta="Contraseña:*"
						placeholder="Contraseña de WordPress"
						required
					/>
				</Form>
			</Formik>
		</Modal>
	)

}

export default ModalModificarCorreo