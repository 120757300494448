import { useState } from "react"
import useCreditos from "../../hooks/useCreditos"
import usePaginacion from "../../hooks/usePaginacion"
import BotonSuperior from "../Boton/BotonSuperior"
import Credito from "./Credito"
import Table from "../Table"
import ModalCrearSolicitud from "./ModalCrearSolicitud"
import { ToastContainer } from "react-toastify"
import ModalDetallesCredito from "./ModalDetallesCredito"
import ModalAbonosCredito from "./ModalAbonosCredito"
import Cargando from "../Cargando"
import { mensajesMutation } from "../../helpers/Variables"
import { useQueryClient } from "@tanstack/react-query"
import { monedaFormato } from "../../helpers/Formato"


const CreditosPersonales = () => {

	const queryClient = useQueryClient()
	const { useGetCredito, useGetSolicitudes, useCrearSolicitud, useModificarRecordatorio } = useCreditos([])
	const creditoQuery = useGetCredito()
	const solicitudesQuery = useGetSolicitudes()

	const crearSolicitud = useCrearSolicitud(mensajesMutation({
		cargando: 'Solicitando Préstamo...',
		exito: 'Solicitud Creada',
		error: 'Ocurrió un Error'
	}))

	const modificarDiaRecordatorio = useModificarRecordatorio(mensajesMutation({
		cargando: 'Modificando Información...',
		exito: 'Día Establecido',
		error: 'Ocurrió un Error'
	}))

	const [paginaSolicitudes, setPaginaSolicitudes, porPaginaSolicitudes] = usePaginacion(4)
	const filasVaciasSolicitudes = paginaSolicitudes > 0 ? Math.max(0, (1 + paginaSolicitudes) * porPaginaSolicitudes - (solicitudesQuery.data?.length)) : 0
	const [modalSolicitud, setModalSolicitud] = useState(false)
	const [modalDetalles, setModalDetalles] = useState(false)
	const [credito, setCredito] = useState({})
	const [modalAbonos, setModalAbonos] = useState(false)


	const handleCrearSolicitud = valores => {
		crearSolicitud.mutate(valores, {
			onSuccess: () => {
				queryClient.invalidateQueries(['creditos', 'solicitudes'])
				setModalSolicitud(false)
			}
		})
	}

	const handleModificarDiaRecordatorio = valores => {
		modificarDiaRecordatorio.mutate(valores)
	}


	return (
		<section id="creditos_personales">
			<h3>Créditos Personales</h3>

			<BotonSuperior accion={() => setModalSolicitud(true)} texto="Solicitar Crédito" />

			<div style={{
				display: 'grid',
				gridTemplateColumns: '1fr 1fr',
				gap: '24px',
				marginBottom: '24px'
			}}>
				<div>
					<h4>Crédito Activo</h4>
					{
						creditoQuery.isLoading ? <Cargando />
							:
							creditoQuery.data &&
							<Credito
								key={creditoQuery.data.id}
								credito={creditoQuery.data}
								detalles={() => { setCredito(creditoQuery.data); setModalDetalles(true) }}
								abonos={() => { setCredito(creditoQuery.data); setModalAbonos(true) }}
							/>

					}
				</div>

				<div>
					<h4>Solicitudes</h4>
					<Table
						encabezado={["Monto", "Estado", "Aceptada", "Motivo Cancelación"]}
						porPagina={porPaginaSolicitudes}
						pagina={paginaSolicitudes}
						setPagina={setPaginaSolicitudes}
						total={solicitudesQuery.data?.length || 0}
						filasVacias={filasVaciasSolicitudes}
						noFixed
					>

						{
							solicitudesQuery.isLoading ? <Cargando tabla />
								: (solicitudesQuery.data?.slice(paginaSolicitudes * porPaginaSolicitudes, paginaSolicitudes * porPaginaSolicitudes + porPaginaSolicitudes)).map(solicitud => (
									<tr key={solicitud.id}>
										<td>{monedaFormato(solicitud.monto)}</td>
										<td>{solicitud.finalizado ? 'Cerrada' : 'Abierta'}</td>
										<td>{solicitud.finalizado ? (solicitud.aceptado ? 'Sí' : 'No') : ''}</td>
										<td>{solicitud.finalizado ? (!solicitud.aceptado ? solicitud.motivo_cancelacion : 'n/a') : ''}</td>
									</tr>
								))
						}
					</Table>
				</div>
			</div>

			<ModalCrearSolicitud modal={modalSolicitud} setModal={setModalSolicitud} crear={handleCrearSolicitud} creditoExistente={creditoQuery.data} />

			<ModalDetallesCredito modal={modalDetalles} setModal={setModalDetalles} credito={credito} editar={datos => handleModificarDiaRecordatorio({ id: credito.id, datos })} />

			{
				credito.id &&
				<ModalAbonosCredito modal={modalAbonos} setModal={setModalAbonos} idCredito={credito.id} />

			}

			<ToastContainer />

		</section>
	)

}

export default CreditosPersonales