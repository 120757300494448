import Modal from "../Modal/Modal"
import { dateAFechaEspañol, monedaFormato } from "../../helpers/Formato"
import Table from "../Table"
import usePaginacion from "../../hooks/usePaginacion"
import useCreditos from "../../hooks/useCreditos"
import Boton from "../Boton/Boton"
import useCreditosAdministracion from "../../hooks/useCreditosAdministracion"
import { mensajesMutation } from "../../helpers/Variables"
import Notificacion from "../../helpers/Notificacion"
import { useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import ModalModificarAbono from "./ModalModificarAbono"


const ModalAbonosCredito = ({ modal, setModal, idCredito, saldoCredito, administracion }) => {
	const [pagina, setPagina, porPagina] = usePaginacion(8)
	const { useGetAbonos } = useCreditos()
	const abonosQuery = useGetAbonos(idCredito)
	const filasVacias = pagina > 0 ? Math.max(0, (1 + pagina) * porPagina - (abonosQuery.data?.length || 0)) : 0

	const queryClient = useQueryClient()

	const { useEliminarAbono, useModificarAbono } = useCreditosAdministracion()

	const eliminarAbono = useEliminarAbono(mensajesMutation({
		cargando: "Eliminando abono...",
		error: "No se pudo eliminar el abono",
		exito: "Abono eliminado exitosamente"
	}))

	const modificarAbono = useModificarAbono(mensajesMutation({
		cargando: "Modificando abono...",
		error: "No se pudo modificar el abono",
		exito: "Abono modificado exitosamente"
	}))

	const handleEliminarAbono = id => {
		eliminarAbono.mutate({ id: idCredito, idAbono: id }, {
			onSuccess: () => {
				queryClient.invalidateQueries(['creditos', idCredito, 'abonos'])
				queryClient.invalidateQueries(['creditos', 'administracion'])
			}
		})
	}

	const handleModificarAbono = (id, datos) => {
		modificarAbono.mutate({ id: idCredito, idAbono: id, datos }, {
			onSuccess: () => {
				queryClient.invalidateQueries(['creditos', idCredito, 'abonos'])
				queryClient.invalidateQueries(['creditos', 'administracion'])
				setModalModificarAbono(false)
			}
		})
	}

	const [modalModificarAbono, setModalModificarAbono] = useState(false)
	const [abono, setAbono] = useState({})

	return (
		<>
			<Modal
				open={modal}
				cerrar={() => setModal(false)}
				titulo="Historial de Abonos del Crédito"
				sinAccion
			>
				{
					abonosQuery.data ?
						<Table
							encabezado={["Fecha", "Abono", "Saldo", ""]}
							porPagina={porPagina}
							pagina={pagina}
							setPagina={setPagina}
							total={abonosQuery.data.length}
							filasVacias={filasVacias}
							noFixed
							topSticky={0}
							modal
						>

							{(abonosQuery.data.slice(pagina * porPagina, pagina * porPagina + porPagina)).map(abono => (
								<tr key={abono.id}>
									<td>{dateAFechaEspañol(abono.fecha)}</td>
									<td>{monedaFormato(abono.abono)}</td>
									<td>{monedaFormato(abono.saldo)}</td>
									{administracion &&
										<td>
											<div className="botones-acciones vertical">
												<Boton
													texto="Editar"
													pequeño
													accion={() => {
														setAbono(abono)
														setModalModificarAbono(true)
													}}
													nofocus
												/>
												<Boton
													texto="Eliminar"
													pequeño
													accion={() => Notificacion.confirmacion(
														"¿Estás seguro de eliminar este abono?",
														() => handleEliminarAbono(abono.id)
													)}
													nofocus
												/>
											</div>
										</td>
									}

								</tr>
							))}
						</Table>
						: <h4>No se han registrado abonos</h4>
				}
			</Modal>

			<ModalModificarAbono
				modal={modalModificarAbono}
				setModal={setModalModificarAbono}
				modificar={val => handleModificarAbono(abono.id, val)}
				abono={abono}
				saldo={saldoCredito}
			/>
		</>
	)

}

export default ModalAbonosCredito