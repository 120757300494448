import Busqueda from "../Busqueda/Busqueda"
import Input from "../Input"


const BusquedaAccesos = ({ buscar }) => {

	return (
		<Busqueda valores={{ pagina: '' }} submit={buscar}>
			<Input
				nombre="pagina"
				etiqueta="Página:"
				placeholder="Nombre de la Página"
			/>
		</Busqueda>
	)

}

export default BusquedaAccesos