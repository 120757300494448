import { useState } from "react"
import { cerrarSesion } from "../helpers/Variables"

const useAnuncios = () => {

	const [anuncios, setAnuncios] = useState({ cargandoAnuncios: true, anuncios: [] })

	// Obtener todos los anuncios
	const getAnuncios = () => {
		fetch(`${process.env.REACT_APP_URI}/anuncios`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
			.then(response => {
				if (response.status === 401)
					cerrarSesion()
				return response.json()
			})
			.then(respuesta => setAnuncios({ cargandoAnuncios: false, anuncios: respuesta }))
			.catch(err => console.error(err))
	}

	// Buscar anuncios con filtros
	const buscarAnuncios = (datos, dashboard = false) => {
		let url = `${process.env.REACT_APP_URI}/anuncios/${datos.estado}?busqueda=${datos.titulo}&festivo=${datos.festivo}`
		if (dashboard)
			url = `${process.env.REACT_APP_URI}/anuncios/activosDashboard`
		fetch(url, {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
			.then(response => {
				if (response.status === 401)
					cerrarSesion()
				return response.json()
			})
			.then(respuesta => setAnuncios({ cargandoAnuncios: false, anuncios: respuesta }))
			.catch(err => console.error(err))
	}

	// Crear un nuevo anuncio
	const crearAnuncio = async datos => {
		const datosFormulario = new FormData()
		datosFormulario.append('titulo', datos.titulo?.trim())
		datosFormulario.append('descripcion', datos.descripcion?.trim())
		datosFormulario.append('fechaInicio', datos['fecha-inicio']?.trim())
		datosFormulario.append('fechaFin', datos['fecha-fin']?.trim())
		datosFormulario.append('imagen', datos.imagen?.trim())
		datosFormulario.append('imagenFILE', datos?.imagenFILE)
		datosFormulario.append('enlace', datos.enlace?.trim())
		datosFormulario.append('dia', datos.dia?.trim())
		datosFormulario.append('mes', datos.mes?.trim())
		datosFormulario.append('festivo', datos.festivo)

		const response = await fetch(`${process.env.REACT_APP_URI}/anuncios/crear`, {
			method: 'POST',
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: datosFormulario
		})
		if (response.status === 401)
			cerrarSesion()
		return await response.json()
	}

	// Modificar un anuncio anuncio
	const modificarAnuncio = async (datos, id) => {
		const datosFormulario = new FormData()
		datosFormulario.append('titulo', datos.titulo.trim())
		datosFormulario.append('descripcion', datos.descripcion.trim())
		datosFormulario.append('fechaInicio', datos['fecha-inicio'].trim())
		datosFormulario.append('fechaFin', datos['fecha-fin'].trim())
		datosFormulario.append('imagen', datos.imagen.trim())
		datosFormulario.append('imagenFILE', datos.imagenFILE)
		datosFormulario.append('enlace', datos.enlace.trim())
		datosFormulario.append('dia', datos.dia)
		datosFormulario.append('mes', datos.mes)

		const response = await fetch(`${process.env.REACT_APP_URI}/anuncios/modificar/${id}`, {
			method: 'PUT',
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: datosFormulario
		})
		if (response.status === 401)
			cerrarSesion()
		return await response.json()
	}

	// Desactivar Anuncio
	const desactivarAnuncio = async id => {
		const response = await fetch(`${process.env.REACT_APP_URI}/anuncios/cerrar/${id}`, {
			method: 'PUT',
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
		if (response.status === 401)
			cerrarSesion()
		return await response.json()
	}

	// Activar Anuncio
	const activarAnuncio = async (id, festivo = false) => {
		const response = await fetch(`${process.env.REACT_APP_URI}/anuncios/activar/${id}`, {
			method: 'PUT',
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`,
				'content-type': 'application/json',
			},
			body: JSON.stringify({ festivo })
		})
		if (response.status === 401)
			cerrarSesion()
		return await response.json()
	}

	return [anuncios, getAnuncios, buscarAnuncios, crearAnuncio, desactivarAnuncio, activarAnuncio, modificarAnuncio]

}

export default useAnuncios