const RecursosIcon = (props) => (
	<svg
		width="1em"
		height="1em"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 25 20"
		{...props}
	>
		<path
			d="M15.029 15h2.5v-2.5h2.5V10h-2.5V7.5h-2.5V10h-2.5v2.5h2.5V15Zm-12.5 5a2.407 2.407 0 0 1-1.765-.734A2.408 2.408 0 0 1 .029 17.5v-15c0-.688.245-1.276.735-1.765A2.404 2.404 0 0 1 2.529 0h7.5l2.5 2.5h10c.687 0 1.276.245 1.766.735s.734 1.078.734 1.765v12.5a2.41 2.41 0 0 1-.734 1.766 2.41 2.41 0 0 1-1.766.734h-20Z"
			fill="#999"
		/>
	</svg>
)

export default RecursosIcon
