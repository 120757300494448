const Tarjeta = ({ children }) => {

	return (
		<div className="tarjeta-login">
			{children}
		</div>
	)

}

export default Tarjeta