const OjoAbierto = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="2.5 2 16 17"
		{...props}
	>
		<path
			d="M3 10.384c4.5-6.666 10.5-6.666 15 0-4.5 6.667-10.5 6.667-15 0Z"
			stroke="#999"
			strokeWidth={2}
			strokeLinejoin="round"
		/>
		<path
			d="M13 10.384a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
			stroke="#999"
			strokeWidth={2}
			strokeLinejoin="round"
		/>
	</svg>
)

export default OjoAbierto
