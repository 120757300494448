import './anuncio.css'
import LinkIcon from "../Icons/LinkIcon"
import { dateAFechaEspañol } from '../../helpers/Formato'

const Anuncio = ({ anuncio }) => {

	return (
		<div className="anuncio">
			{
				anuncio.imagen ? <img alt="img" src={anuncio.imagen} /> : null
			}
			<div className='informacion'>
				<h4>{anuncio.titulo}</h4>
				{
					anuncio.descripcion ? <p>{anuncio.descripcion}</p> : null
				}
				{
					anuncio.enlace ? <a href={anuncio.enlace} target="_blank" rel="noreferrer"><LinkIcon /> {anuncio.enlace}</a> : null
				}
				{
					anuncio.fechaInicio ?
						<span className='felcha-inicio'>{anuncio.fechaFin ? 'Inicio: ' : 'Fecha: '} {dateAFechaEspañol(anuncio.fechaInicio)}</span> :
						null
				}
				{
					anuncio.fechaFin ? <span className='felcha-fin'>Fin: {dateAFechaEspañol(anuncio.fechaFin)}</span> : null
				}
			</div>
		</div>
		
	)	
	
}

export default Anuncio