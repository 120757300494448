import Input from "../Input"
import Modal from "../Modal/Modal"
import TextArea from "../TextArea"
import { Formik, Form } from "formik"
import Select from "../Select"
import { categorias } from "../../helpers/CategoriasRecursos"

const ModalAgregarRecurso = ({ modal, setModal, crear }) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Agregar Recurso"
			idFormulario="agregar_recurso"
			textoBoton="Agregar"
		>
			<Formik
				initialValues={{
					titulo: '',
					descripcion: '',
					enlace: '',
					imagen: '',
					categoria: 'Programación'
				}}
				onSubmit={crear}
			>
				<Form id="agregar_recurso">
					<Select
						nombre="categoria"
						etiqueta="Categoria:*"
					>
						{
							categorias.map(categoria => (
								<option value={categoria} key={categoria}>{categoria}</option>
							))
						}
					</Select>
					<Input
						nombre="titulo"
						placeholder="Título del Recurso"
						etiqueta="Título:*"
						required
					/>
					<TextArea
						nombre="descripcion"
						etiqueta="Descripción"
						placeholder="Descripción de la Recurso"
					/>
					<Input
						nombre="imagen"
						placeholder="URL de la Imagen del Recurso"
						etiqueta="Imagen:"
					/>
					<Input
						nombre="enlace"
						placeholder="Enlace del Recurso"
						etiqueta="Enlace:*"
						required
						tipo="url"
					/>
				</Form>
			</Formik>
		</Modal>
	)

}

export default ModalAgregarRecurso