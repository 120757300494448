const VacacionesIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 20"
    {...props}
  >
    <path
      d="M4.545 5.342 8.541 6.8c1.375-3.189 3.376-5.641 5.44-6.786a11.724 11.724 0 0 0-9.725 4.364c-.267.33-.11.822.29.963ZM9.72 7.225l9.335 3.394c1.399-4.743.728-9.048-1.669-9.92a2.693 2.693 0 0 0-.905-.158C14.212.54 11.46 3.245 9.72 7.225ZM20.44 2.36c.245.635.42 1.353.514 2.159.225 1.948-.054 4.223-.739 6.52l4.016 1.46c.396.147.831-.132.841-.551A11.67 11.67 0 0 0 20.44 2.36Zm.255 15.139h-8.102l2.544-6.974-2.353-.854L9.93 17.5H1.907A1.875 1.875 0 0 0 .03 19.375c0 .344.28.625.626.625h21.29c.347 0 .627-.28.627-.59 0-1.07-.841-1.91-1.878-1.91Z"
      fill="#999"
    />
  </svg>
)

export default VacacionesIcon