import { Field } from "formik"
import { useState } from "react"
import OjoAbierto from "./Icons/OjoAbierto"
import OjoCerrado from "./Icons/OjoCerrado"


const Input = ({
	nombre,
	tipo = "text",
	etiqueta,
	placeholder,
	required,
	autocomplete,
	contraseña,
	lectura,
	...props
}) => {
	const [tipoInput, setTipoInput] = useState(tipo)

	const togglePass = () => {
		setTipoInput(tipoInput === 'text' ? 'password' : 'text')
	}

	return (
		<div className="input">
			<label>{etiqueta}</label>
			<span>
				<Field
					name={nombre}
					type={tipoInput}
					placeholder={placeholder}
					required={required}
					autoComplete={autocomplete ? 'on' : 'off'}
					readOnly={lectura ? true : false}
					{...props}
				/>
				{
					contraseña ?
						<i onClick={togglePass}>
							{
								tipoInput === 'text' ? <OjoAbierto /> : <OjoCerrado />
							}
						</i>
						: null
				}
			</span>
		</div>
	)

}

export default Input