import './modal.css'
import './modal.responsive.css'
import ReactModal from 'react-modal'
import Boton from '../Boton/Boton'
ReactModal.setAppElement('#root')


const Modal = ({ open, cerrar, titulo, idFormulario, textoBoton, sinAccion, children, classExtra, textoBotonTitulo, accionTitulo }) => {
	const body = document.querySelector('body')
	const fondo = document.querySelector('.app .layout-fondo')

	const modalAbierto = () => {
		if (body.clientHeight > window.innerHeight) {
			body.style.paddingRight = '17px'
			fondo.style.transform = 'translate(calc(-50% - 8.5px), -50%)'
		}
		const primerInput = document.querySelector(`#${idFormulario} input`)
		if(primerInput){
			primerInput.focus()
		}
	}

	const reestablecerEstilos = () => {
		body.style.paddingRight = '0px'
		fondo.style.transform = 'translate(-50%, -50%)'
	}

	return (
		<ReactModal
			isOpen={open}
			contentLabel="Modal Modificado"
			onRequestClose={cerrar}
			onAfterClose={reestablecerEstilos}
			onAfterOpen={modalAbierto}
			className={`modal ${classExtra ? classExtra : ''}`}
			overlayClassName="overlay"
			closeTimeoutMS={300}
		>
			<div className="modal-titulo">
				<h3>{titulo}</h3>
				{
					textoBotonTitulo ?
						<Boton texto={textoBotonTitulo} accion={accionTitulo} pequeño noFocus />
						: null
				}
			</div>
			<div className="modal-contenido" style={{ overflow: 'auto' }}>
				{children}
			</div>
			<div className="modal-botones">
				<Boton texto={sinAccion ? 'Cerrar' : 'Cancelar'} cancelar accion={cerrar} />
				{!sinAccion ? <Boton texto={textoBoton} idFormulario={idFormulario} /> : null}
			</div>
		</ReactModal>
	)

}

export default Modal