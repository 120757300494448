import './boton.css'

const Boton = ({
	texto,
	estilo,
	pequeño,
	cancelar,
	formulario,
	idFormulario,
	accion,
	noFocus
}) => {

	const clases = `boton ${pequeño ? 'boton-pequeño' : ''} ${cancelar ? 'boton-cancelar' : ''} ${noFocus ? 'no-focus' : ''}`

	if (formulario || idFormulario) {
		return (
			<input
				type="submit"
				className={clases}
				style={estilo}
				value={texto}
				form={idFormulario}
			/>
		)
	}

	return (
		<button
			type="button"
			className={clases}
			style={estilo}
			onClick={accion}
		>
			{texto}
		</button>
	)

}

export default Boton