import './cargando.css'

const Cargando = ({ tabla }) => {

	if (tabla)
		return (
			<tr>
				<td className='cargando-tabla' colSpan={10}>
					<span className='loader'></span>
				</td>
			</tr>
		)

	return (
		<div className='cargando'>
			<span className='loader'></span>
		</div>
	)

}

export default Cargando