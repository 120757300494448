const CerrarSesionIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M20.029 7.2a1.2 1.2 0 1 1-2.4 0V4.1l-5.15 5.15a1.2 1.2 0 0 1-.85.35 1.16 1.16 0 0 1-.85-.35 1.198 1.198 0 0 1 0-1.7l5.15-5.15h-3.1a1.2 1.2 0 1 1 0-2.4h6a1.2 1.2 0 0 1 1.2 1.2v6Zm-4.4 3.2a1.2 1.2 0 0 0-1.2 1.2v6h-12v-12h6a1.2 1.2 0 0 0 0-2.4h-6.4a2.01 2.01 0 0 0-2 2V18a2.01 2.01 0 0 0 2 2h12.8a2.01 2.01 0 0 0 2-2v-6.4a1.2 1.2 0 0 0-1.2-1.2Z"
      fill="#999"
    />
  </svg>
)

export default CerrarSesionIcon