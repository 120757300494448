import { useEffect, useState } from "react"
import { dateAFechaEspañol } from "../../helpers/Formato"
import Notificacion from "../../helpers/Notificacion"
import { diaSemana } from "../../helpers/Variables"
import usePaginacion from "../../hooks/usePaginacion"
import useVacaciones from "../../hooks/useVacaciones"
import Boton from "../Boton/Boton"
import Cargando from "../Cargando"
import Table from "../Table"
import ModalRechazarVacaciones from "./ModalRechazarVacaciones"
import { useNavigate } from "react-router-dom";

const VacacionesAdministracion = () => {
	const {
		solicitudes: { cargandoSolicitudes, solicitudes },
		cancelarSolicitud,
		getTodasSolicitudes,
		aceptarSolicitud,
		getUsers,
		users: { cargandoUsuarios, users }
	} = useVacaciones()
	const [pagina, setPagina, porPagina] = usePaginacion(4)
	const [pagina_users, setPagina_users, porPagina_users] = usePaginacion(12)
	const filasVacias = pagina > 0 ? Math.max(0, (1 + pagina) * porPagina - solicitudes.length) : 0
	const filasVacias_users = pagina_users > 0 ? Math.max(0, (1 + pagina_users) * porPagina_users - users.length) : 0
	const [modal, setModal] = useState(false)
	const [user_id_val, setUserIdVal] = useState(0)
	const navigate = useNavigate()

	// eslint-disable-next-line
	useEffect(getTodasSolicitudes, [])
	// eslint-disable-next-line
	useEffect(getUsers, [])

	const aceptar = id => {
		const idNotificacion = Notificacion.cargando('Aceptando Solicitud...')
		aceptarSolicitud(id)
			.then(resultado => {
				if (resultado === 1) {
					Notificacion.exito(idNotificacion, 'Solicitud Aceptada')
					getTodasSolicitudes()
				} else
					Notificacion.error(idNotificacion, 'Ocurrió un Error')
			})
			.catch(err => console.error(err))
	}

	const rechazar = (value) => {
		console.log('clicked', value)
		const idNotificacion = Notificacion.cargando('Cancelando Solicitud...')
		cancelarSolicitud(value.user_id_queried, value.motivo)
			.then(resultado => {
				if (resultado === 1) {
					Notificacion.exito(idNotificacion, 'Solicitud Cancelada')
					getTodasSolicitudes()
				} else
					Notificacion.error(idNotificacion, 'Ocurrió un Error')
			})
			.catch(err => console.error(err))
	}
/*
	function mapView() {
		navigate("/vacaciones/mapa");
	}*/

	function AdminView() {
		navigate("/vacaciones/admin");
	}

	function userDetails(user_id) {
		navigate("/vacaciones/userDetails/" + user_id)
	}

	return (
		<div>
			<div className="flexed title-button-container">
				<Boton texto="Solicitar Vacaciones" accion={() => AdminView()} noFocus />
			</div>

			<h3>Administración de Solicitudes de Vacaciones</h3>
			{solicitudes.length > 0 ? <Table
				encabezado={['Días', 'Usuario', 'Acciones']}
				porPagina={porPagina}
				pagina={pagina}
				setPagina={setPagina}
				total={solicitudes.length}
				filasVacias={filasVacias}
				noFixed

			>

				{
					cargandoSolicitudes ? <Cargando tabla />
						: (solicitudes.slice(pagina * porPagina, pagina * porPagina + porPagina)).map(solicitud => (
							<tr key={solicitud.id_solicitud_vacaciones}>
								<td>
									{
										solicitud.fechas.split(';').map((fecha, i) => (
											<p key={i}>{`${diaSemana[(new Date(`${fecha} 00:00:00`)).getDay()]}, ${dateAFechaEspañol(fecha)}`}</p>
										))
									}
								</td>
								<td>
									{solicitud.usuario.nombre}
								</td>
								<td>
									<div className="botones-acciones">
										<Boton texto="Cancelar" pequeño accion={() => { setModal(true); setUserIdVal(solicitud.id_solicitud_vacaciones) }} noFocus />
										<Boton texto="Aceptar" pequeño accion={() => aceptar(solicitud.id_solicitud_vacaciones)} noFocus />
									</div>
								</td>
							</tr>
						))
				}
			</Table> : (
				<p>No hay solicitudes de vacaciones disponibles.</p>
			)}

			<br></br>

			<Table
				encabezado={['Nombre', 'Días totales', 'Días usados', 'Días disponibles']}
				porPagina={porPagina_users}
				pagina={pagina_users}
				setPagina={setPagina_users}
				total={users.length}
				filasVacias={filasVacias_users}
				noFixed
			>
				{cargandoUsuarios ? (
					<Cargando tabla />
				) : (
					users
						.slice(pagina_users * porPagina_users, pagina_users * porPagina_users + porPagina_users)
						.map(user => (
							<tr className="hovered-table" key={user.id} onClick={() => userDetails(user.id)}  >
								<td className="col-50-l" onClick={(e) => e.stopPropagation()}>{user.nombre}</td>
								<td  onClick={(e) => e.stopPropagation()}>{user.vacaciones + user.total_dias ?? 0}</td>
								<td onClick={(e) => e.stopPropagation()}>{user.total_dias ?? 0}</td>
								<td onClick={(e) => e.stopPropagation()}>{user.vacaciones}</td>
							</tr>
						))
				)}
			</Table>

			<ModalRechazarVacaciones modal={modal} setModal={setModal} rechazar={rechazar} user_rejected_id={user_id_val} />
		</div>
	)

}

export default VacacionesAdministracion