import Busqueda from "../Busqueda/Busqueda"
import Input from "../Input"
import Select from "../Select"

const BusquedaAnuncios = ({ buscar }) => {

	return (
		<Busqueda valores={{ festivo: '0', estado: '', titulo: '' }} submit={buscar}>
			<Select nombre="festivo" etiqueta="Tipo:">
				<option value="0">Anuncio</option>
				<option value="1">Días Festivos</option>
			</Select>
			<Select nombre="estado" etiqueta="Estado:">
				<option value="">Todos</option>
				<option value="activos">Activo</option>
				<option value="inactivos">Inactivo</option>
			</Select>
			<Input
				nombre="titulo"
				etiqueta="Título:"
				placeholder="Título del Anuncio"
			/>
		</Busqueda>
	)

}

export default BusquedaAnuncios