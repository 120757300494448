import Login from "./components/Login/Login";
import { Routes, Route } from 'react-router-dom'
import Dashboard from "./components/Dashboard/Dashboard";
import Layout from "./components/Layout";
import Recursos from "./components/Recursos/Recursos";
import Accesos from "./components/Accesos/Accesos";
import Servicios from "./components/Servicios/Servicios";
import Correos from "./components/Correos/Correos";
import Creditos from "./components/Creditos/Creditos";
import Vacaciones from "./components/Vacaciones/Vacaciones";
import VacacionesMapa from "./components/Vacaciones/vacacionesMapa";
import VacacionesUserDetails from "./components/Vacaciones/vacacionesPerUser";
import VacacionesForAdmin from "./components/Vacaciones/VacacionesForAdmin";
import Anuncios from "./components/Anuncios/Anuncios";
import 'react-toastify/dist/ReactToastify.css'
import Cumpleaños from "./components/Cumpleaños/Cumpleaños";

const App = () => {

	const token = localStorage.getItem('token')

	if (!token && window.location.pathname !== '/login') {
		window.location.href = '/login'
		return ''
	} else if (token && window.location.pathname === '/login') {
		window.location.href = '/dashboard'
		return ''
	}

	return (
		<Routes>
			<Route path="/login" element={<Login title="Intranet Selfish | Login" />} />
			<Route path="/" element={<Layout frase="¡Hola de Nuevo!" />}>
				<Route path="dashboard" element={<Dashboard
					title="Intranet Selfish | Dashboard"
					encabezado="dashboard"
					frase="¡Hola de Nuevo!"
					fondo="/assets/Background.jpg"
				/>} />
				<Route path="recursos" element={<Recursos
					title="Intranet Selfish | Recursos"
					encabezado="recursos"
					frase="Y esta es la cueva de las maravillas."
					fondo="/assets/Recursos_bg.jpg"
				/>} />
				<Route path="accesos" element={<Accesos
					title="Intranet Selfish | Accesos"
					encabezado="accesos"
					frase="Un gran poder conlleva una gran responsabilidad."
					fondo="/assets/Accesos.jpg"
				/>} />
				<Route path="suscripciones" element={<Servicios
					title="Intranet Selfish | Suscripciones"
					encabezado="suscripciones"
					frase="De todo un poco para aprender!"
					fondo="/assets/Suscripciones.jpg"
				/>} />
				<Route path="correosInternos" element={<Correos
					title="Intranet Selfish | Correos"
					encabezado="correos internos"
					frase="Cuentas activas."
					fondo="/assets/Correos.jpg"
				/>} />
				<Route path="vacaciones" element={<Vacaciones
					title="Intranet Selfish | Vacaciones"
					encabezado="vacaciones"
					frase="Descansooo"
					fondo="/assets/Vacaciones.jpg"
				/>} />
				<Route path="vacaciones/mapa" element={<VacacionesMapa
					title="Intranet Selfish | Vacaciones"
					encabezado="vacaciones"
					frase="Descansooo"
					fondo="/assets/Vacaciones.jpg"
				/>} />
				<Route path="vacaciones/userDetails/:user_id" element={<VacacionesUserDetails
					title="Intranet Selfish | Vacaciones"
					encabezado="vacaciones"
					frase="Descansooo"
					fondo="/assets/Vacaciones.jpg"
				/>} />
				<Route path="vacaciones/admin" element={<VacacionesForAdmin
					title="Intranet Selfish | Vacaciones"
					encabezado="vacaciones"
					frase="Descansooo"
					fondo="/assets/Vacaciones.jpg"
				/>} />
				<Route path="cumpleanos" element={<Cumpleaños
					title="Intranet Selfish | Cumpleaños"
					encabezado="cumpleaños"
					frase=""
					fondo=""
				/>} />
				<Route path="cumpleanos/mapa" element={<VacacionesMapa
					title="Intranet Selfish | Cumpleaños"
					encabezado="cumpleaños"
					frase="Descansooo"
					fondo="/assets/Vacaciones.jpg"
				/>} />
				<Route path="crediselfish" element={<Creditos
					title="Intranet Selfish | CrediSelfish"
					encabezado="crediselfish"
					frase="Solicita y verifica tus créditos."
					fondo="/assets/Crediselfish.jpg"
				/>} />
				<Route path="anuncios" element={<Anuncios
					title="Intranet Selfish | Anuncios"
					encabezado="anuncios"
					frase="Todo lo que debes saber."
					fondo="/assets/Anuncios.jpg"
				/>} />
				
			</Route>
			<Route path="*" element={<h1>404</h1>} />
		</Routes>
	)

}

export default App