import EditarIcon from '../Icons/EditarIcon'
import LinkIcon from '../Icons/LinkIcon'
import './recurso.css'
import './recurso.responsive.css'

const Recurso = ({ recurso, modificar }) => {

	return (
		<div className='recurso'>
			{
				recurso.imagen ?
					<div className='img'>
						<img alt='recurso' src={recurso.imagen} />
					</div>
					: null
			}
			<div className='contenido'>
				<h4>{recurso.titulo}</h4>
				{
					recurso.descripcion ? <p>{recurso.descripcion}</p> : null
				}
				<a href={recurso.enlace} target="_blank" rel="noreferrer">
					<LinkIcon />
					{recurso.enlace}
				</a>
			</div>
			{
				modificar ?
					<div className='acciones'>
						<i onClick={modificar}><EditarIcon /></i>
					</div>
					: null
			}

		</div>
	)

}

export default Recurso