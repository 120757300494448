import { useState } from "react"
import { cerrarSesion } from "../helpers/Variables"

const useCorreos = () => {

	const [correos, setCorreos] = useState({ cargandoCorreos: true, correos: [] })

	// Obtener todos los correos
	const getCorreos = () => {
		fetch(`${process.env.REACT_APP_URI}/correosInternos`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
			.then(response => {
				if (response.status === 401)
					cerrarSesion()
				return response.json()
			})
			.then(respuesta => setCorreos({ cargandoCorreos: false, correos: respuesta }))
			.catch(err => console.error(err))
	}

	// Buscar correos con filtros
	const buscarCorreos = datos => {
		fetch(`${process.env.REACT_APP_URI}/correosInternos?busqueda=${datos.correo}`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
			.then(response => {
				if (response.status === 401)
					cerrarSesion()
				return response.json()
			})
			.then(respuesta => setCorreos({ cargandoCorreos: false, correos: respuesta }))
			.catch(err => console.error(err))
	}

	// Crear un nuevo correo
	const crearCorreo = async datos => {
		const response = await fetch(`${process.env.REACT_APP_URI}/correosInternos/crear`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
				'accept': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({
				nombrePersonal: datos.nombre.trim(),
				correo: datos.correo.trim() === '' ? null : datos.correo.trim(),
				contraseña: datos.contraseña.trim() === '' ? null : datos.contraseña.trim(),
			})
		})
		if (response.status === 401)
			cerrarSesion()
		return await response.json()
	}

	// Modificar correo
	const modificarCorreos = async (datos, id) => {
		const response = await fetch(`${process.env.REACT_APP_URI}/correosInternos/modificar/${id}`, {
			method: 'PUT',
			headers: {
				'content-type': 'application/json',
				'accept': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({
				nombrePersonal: datos.nombre.trim(),
				correo: datos.correo.trim() === '' ? null : datos.correo.trim(),
				contraseña: datos.contraseña.trim() === '' ? null : datos.contraseña.trim(),
			})
		})
		if (response.status === 401)
			cerrarSesion()
		return await response.json()
	}

	return [correos, getCorreos, buscarCorreos, crearCorreo, modificarCorreos]

}

export default useCorreos