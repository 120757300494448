import Notificacion from "./Notificacion"

export const rolAdministrador = 'Super Administrador'

export const cerrarSesion = () => {
	localStorage.clear()
	window.location.href = '/login'
}

export const meses = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre'
]

export const dias = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]

export const diaSemana = [
	'Domingo',
	'Lunes',
	'Martes',
	'Miércoles',
	'Jueves',
	'Viernes',
	'Sábado'
]

export const mensajesMutation = ({ cargando, exito, error }) => ({
	onMutate: () => {
		const id = Notificacion.cargando(cargando)
		return { idNotificacion: id }
	},
	onSuccess: (_data, _variables, context) => {
		Notificacion.exito(context.idNotificacion, exito)
	},
	onError: (_error, _variables, context) => {
		Notificacion.error(context.idNotificacion, error)
	}
})