const EditarIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		viewBox="0 0 20 20"
		fill="none"
		{...props}
	>
		<g clipPath="url(#a)" fill="#999">
			<path d="m16.494.89 3 3-2.287 2.288-3-3L16.494.89ZM5.506 14.878h3l7.287-7.287-3-3-7.287 7.287v3Z" />
			<path d="M16.506 17.878H5.664c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H2.506v-14h6.847l2-2H2.506c-1.103 0-2 .896-2 2v14c0 1.104.897 2 2 2h14a2 2 0 0 0 2-2V9.21l-2 2v6.668Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" transform="translate(0 .384)" d="M0 0h20v20H0z" />
			</clipPath>
		</defs>
	</svg>
)

export default EditarIcon