import Modal from "../Modal/Modal"
import { Formik, Form } from "formik"
import UsuarioWordPress from "./UsuariosWordPress"


const ModalModificarAcceso = ({ modal, setModal, usuarios, modificar }) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Modificar Accesos de WordPress"
			idFormulario="modificar_wordpress"
			textoBoton="Modificar"
		>
			<Formik
				initialValues={{ wp: usuarios.wp }}
				onSubmit={valores => modificar(valores.wp, usuarios.id)}
			>
				{({ values }) => (
					<Form id='modificar_wordpress'>
						<UsuarioWordPress usuarios={values.wp} />
					</Form>
				)}
			</Formik>
		</Modal>
	)

}

export default ModalModificarAcceso