import { axiosInstance } from "../helpers/axios"
import { useMutation, useQuery } from "@tanstack/react-query"


const useCreditosAdministracion = () => {

	// Obtener todos los creditos activos
	const useGetCreditos = () => {
		return useQuery(
			['creditos', 'administracion'],
			async () => (await axiosInstance.get('/creditos')).data
		)
	}

	// Obtener todos los creditos liquidados
	const useGetCreditosLiquidados = () => {
		return useQuery(
			['creditos', 'liquidados', 'administracion'],
			async () => (await axiosInstance.get('/creditos/liquidados')).data
		)
	}

	// Obtener todas las solicitudes
	const useGetSolicitudes = () => {
		return useQuery(
			['creditos', 'solicitudes', 'administracion'],
			async () => (await axiosInstance.get('/creditos/solicitudes')).data
		)
	}

	// Cancelar una solicitud de credito
	const useCancelarSolicitud = mensajes => {
		return useMutation(
			['creditos', 'cancelarSolicitud'],
			async ({ id, datos }) => (await axiosInstance.put(`/creditos/solicitudes/${id}/cancelar`, datos)).data,
			mensajes
		)
	}

	// Aceptar solicitud de credito
	const useAceptarSolicitud = mensajes => {
		return useMutation(
			['creditos', 'aceptarSolicitud'],
			async ({ id, datos }) => (await axiosInstance.put(`/creditos/solicitudes/${id}/aceptar`, datos)).data,
			mensajes
		)
	}

	// Registrar abono a credito
	const useRegistrarAbono = mensajes => {
		return useMutation(
			['creditos', 'abonarPago'],
			async ({ id, valores }) => (await axiosInstance.post(`/creditos/${id}/abonos`, valores)).data,
			mensajes
		)
	}

	// Eliminar crédito
	const useEliminarCredito = mensajes => {
		return useMutation(
			['creditos', 'eliminarCredito'],
			async id => (await axiosInstance.delete(`/creditos/${id}`)).data,
			mensajes
		)
	}

	// Eliminar abono
	const useEliminarAbono = mensajes => {
		return useMutation(
			['creditos', 'eliminarAbono'],
			async ({ id, idAbono }) => (await axiosInstance.delete(`/creditos/${id}/abonos/${idAbono}`)).data,
			mensajes
		)
	}

	// Modificar abono
	const useModificarAbono = mensajes => {
		return useMutation(
			['creditos', 'modificarAbono'],
			async ({ id, idAbono, datos }) => (await axiosInstance.put(`/creditos/${id}/abonos/${idAbono}`, datos)).data,
			mensajes
		)
	}

	return {
		useGetCreditos,
		useGetCreditosLiquidados,
		useGetSolicitudes,
		useCancelarSolicitud,
		useRegistrarAbono,
		useAceptarSolicitud,
		useEliminarCredito,
		useEliminarAbono,
		useModificarAbono
	}

}

export default useCreditosAdministracion