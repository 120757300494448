import Input from "../Input"
import Modal from "../Modal/Modal"
import { Formik, Form } from "formik"

const ModalAgregarCorreo = ({ modal, setModal, crear }) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Agregar Correo"
			idFormulario="agregar_correo"
			textoBoton="Agregar"
		>
			<Formik
				initialValues={{
					nombre: '',
					correo: '',
					contraseña: ''
				}}
				onSubmit={crear}
			>
				<Form id='agregar_correo'>
					<Input
						nombre="nombre"
						etiqueta="Nombre Personal:"
						placeholder="Nombre de la Persona"
					/>
					<Input
						nombre="correo"
						etiqueta="Correo:*"
						placeholder="Correo Utilizado en WordPress"
						required
					/>
					<Input
						nombre="contraseña"
						etiqueta="Contraseña:*"
						placeholder="Contraseña de WordPress"
						required
					/>
				</Form>
			</Formik>
		</Modal>
	)

}

export default ModalAgregarCorreo