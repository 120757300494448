import Input from "../Input"
import Modal from "../Modal/Modal"
import { Formik, Form } from "formik"
import Select from "../Select"
import { dias, meses } from "../../helpers/Variables"

const ModalFijarDia = ({ modal, setModal, crear }) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Fijar Día Festivo"
			idFormulario="fijar_dia"
			textoBoton="Fijar"
		>
			<Formik
				initialValues={{
					titulo: '',
					mes: '1',
					dia: '1',
					festivo: 1
				}}
				onSubmit={crear}
			>

				<Form id='fijar_dia'>
					<Input
						nombre="titulo"
						placeholder="Título del Anuncio"
						etiqueta="Título:"
						required
					/>
					<Select
						nombre="mes"
						etiqueta="Mes:"
					>
						{
							meses.map((mes, i) => (
								<option key={i} value={i + 1}>{mes}</option>
							))
						}
					</Select>
					<Select
						nombre="dia"
						etiqueta="Día:"
					>
						{
							dias.map(numero => (
								<option key={numero} value={numero}>{numero}</option>
							))
						}
					</Select>
				</Form>
			</Formik>
		</Modal>
	)

}

export default ModalFijarDia