import Input from "../Input"
import Modal from "../Modal/Modal"
import TextArea from "../TextArea"
import { Formik, Form } from "formik"
import File from "../File"

const ModalPublicarAnuncio = ({ modal, setModal, crear }) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Publicar Anuncio"
			idFormulario="crear_anuncio"
			textoBoton="Publicar"
		>
			<Formik
				initialValues={{
					titulo: '',
					descripcion: '',
					'fecha-inicio': '',
					'fecha-fin': '',
					imagen: '',
					imagenFILE: '',
					enlace: ''
				}}
				onSubmit={crear}
			>
				{
					({ setFieldValue }) => (

						<Form id='crear_anuncio'>
							<Input
								nombre="titulo"
								placeholder="Título del Anuncio"
								etiqueta="Título:*"
								required
							/>
							<TextArea
								nombre="descripcion"
								etiqueta="Descripción"
								placeholder="Descripción del Anuncio"
							/>
							<Input
								nombre="fecha-inicio"
								etiqueta="Fecha Inicial:"
								tipo="date"
							/>
							<Input
								nombre="fecha-fin"
								etiqueta="Fecha Final:"
								tipo="date"
							/>
							<Input
								nombre="imagen"
								placeholder="URL de la Imágen del Anuncio"
								etiqueta="Imágen:"
								tipo="url"
							/>
							<File
								nombre="imagenFILE"
								etiqueta="ó Arrastra o Selecciona la Imágen"
								setFieldValue={setFieldValue}
							/>
							<Input
								nombre="enlace"
								placeholder="Enlace del Anuncio"
								etiqueta="Enlace:"
								tipo="url"
							/>
						</Form>
					)
				}
			</Formik>
		</Modal>
	)

}

export default ModalPublicarAnuncio