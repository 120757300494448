import "./encabezado.css"
import "./encabezado.responsive.css"
import { mostrarMenu } from "../helpers/ToggleNavegacion"
import { dateAFechaEspañol } from "../helpers/Formato"
import BurgerIcon from "./Icons/BurgerIcon"

const Encabezado = ({ titulo, frase }) => {

	return (
		<div className="encabezado">
			<div className="fila fila-menu-movil">
				<div className="menu-movil-abrir" onClick={mostrarMenu}>
					<BurgerIcon />
				</div>
				<h2>{titulo || 'Ejemplo'}</h2>
			</div>
			<div className="fila">
				<p>{frase}</p>
				<p>{dateAFechaEspañol(new Date())}</p>
			</div>
		</div>
	)
}

export default Encabezado
