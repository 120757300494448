import Busqueda from "../Busqueda/Busqueda"
import Input from "../Input"

const BusquedaRecursos = ({ titulo, buscar, formulario }) => {

	return (
		<Busqueda valores={{ titulo }} submit={buscar} formulario={formulario}>
			<Input
				nombre="titulo"
				etiqueta="Título:"
				placeholder="Título del Recurso"
			/>
		</Busqueda>
	)

}

export default BusquedaRecursos