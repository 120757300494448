import { useEffect, useState } from 'react'
import Notificacion from '../../helpers/Notificacion'
import usePaginacion from '../../hooks/usePaginacion'
import useServicios from '../../hooks/useServicios'
import Boton from '../Boton/Boton'
import BotonSuperior from '../Boton/BotonSuperior'
import Table from '../Table'
import BusquedaServicios from './BusquedaServicios'
import ModalAgregarServicio from './ModalAgregarServicio'
import { ToastContainer } from "react-toastify"
import ModalModificarServicio from './ModalModificarServicio'
import { rolAdministrador } from '../../helpers/Variables'
import Encabezado from '../Encabezado'
import Cargando from '../Cargando'

const Servicios = ({ title, encabezado, frase, fondo }) => {
	document.title = title

	const [modalAgregar, setModalAgregar] = useState(false)
	const [modalModificar, setModalModificar] = useState(false)
	const [servicioModificar, setServicioModificar] = useState({})
	const [{ cargandoServicios, servicios }, getServicios, buscarServicios, crearServicio, modificarServicio] = useServicios()
	const [pagina, setPagina, porPagina] = usePaginacion(10)
	const filasVacias = pagina > 0 ? Math.max(0, (1 + pagina) * porPagina - servicios.length) : 0
	const rol = localStorage.getItem('rol')

	// eslint-disable-next-line
	useEffect(getServicios, [])

	const crear = valores => {
		const idNotificacion = Notificacion.cargando('Agregando Servicio...')

		crearServicio(valores)
			.then(respuesta => {
				if (respuesta === 1) {
					Notificacion.exito(idNotificacion, 'Servicio Agregado')
					document.querySelector('.busqueda input[type="submit"]').click()
					setModalAgregar(false)
				} else
					Notificacion.error(idNotificacion, 'Ocurrió un Error')
			})
			.catch(err => console.error(err))
	}

	const modificar = (valores, id) => {
		const idNotificacion = Notificacion.cargando('Modificando Servicio...')

		modificarServicio(valores, id)
			.then(respuesta => {
				if (respuesta === 1) {
					Notificacion.exito(idNotificacion, 'Servicio Modificado')
					document.querySelector('.busqueda input[type="submit"]').click()
					setModalModificar(false)
				} else
					Notificacion.error(idNotificacion, 'Ocurrió un Error')
			})
			.catch(err => console.error(err))
	}

	return (
		<div className='layout-contenido'>

			<img className="layout-fondo" src={fondo} alt="fondo" />

			<Encabezado titulo={encabezado} frase={frase} />

			{
				rol === rolAdministrador ?
					<BotonSuperior texto="Agregar Suscripción" accion={() => setModalAgregar(true)} />
					: null
			}

			<BusquedaServicios buscar={val => { setPagina(0); buscarServicios(val) }} />

			<Table
				encabezado={['Enlace', 'Usuario', 'Contraseña', ...(rol === rolAdministrador ? ['Acciones'] : [])]}
				porPagina={porPagina}
				pagina={pagina}
				setPagina={setPagina}
				total={servicios.length}
				filasVacias={filasVacias}
			>
				{
					cargandoServicios ? <Cargando tabla />
						: (servicios.slice(pagina * porPagina, pagina * porPagina + porPagina)).map(servicio => (
							<tr key={servicio.idAccesoPlataforma}>
								<td>{servicio.enlace}</td>
								<td>{servicio.usuario}</td>
								<td>{servicio.contraseña}</td>
								{
									rol === rolAdministrador ? <td>
										<div className='botones-acciones'>
											{
												<Boton texto="Modificar" pequeño noFocus accion={() => { setServicioModificar(servicio); setModalModificar(true) }} />
											}
										</div>
									</td>
										: null
								}
							</tr>
						))
				}
			</Table>

			<ModalAgregarServicio modal={modalAgregar} setModal={setModalAgregar} crear={crear} />
			<ModalModificarServicio modal={modalModificar} setModal={setModalModificar} servicio={servicioModificar} modificar={modificar} />

			<ToastContainer />
		</div>
	)

}

export default Servicios