import Busqueda from "../Busqueda/Busqueda"
import Input from "../Input"

const BusquedaServicios = ({ buscar }) => {

	return (
		<Busqueda valores={{ enlace: '' }} submit={buscar}>
			<Input
				nombre="enlace"
				etiqueta="Enlace:"
				placeholder="Enlace de la Suscripción"
			/>
		</Busqueda>
	)

}

export default BusquedaServicios