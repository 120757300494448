import './busqueda.css'
import './busqueda.responsive.css'
import Boton from "../Boton/Boton"
import { Formik, Form } from "formik"

const Busqueda = ({ valores, submit, formulario, children }) => {

	return (
		<Formik
			initialValues={valores}
			onSubmit={submit}
			innerRef={formulario}
		>
			<Form className="busqueda" >
				{children}
				<Boton texto="Buscar" formulario noFocus />
			</Form>
		</Formik>
	)

}

export default Busqueda