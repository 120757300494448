import Modal from "../Modal/Modal"
import { Formik, Form } from "formik"
import Input from "../Input"
import Boton from "../Boton/Boton"
import { dateAFechaEspañol, monedaFormato } from "../../helpers/Formato"


const ModalDetallesCredito = ({ modal, setModal, credito, editar, administracion }) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Detalle del Crédito"
			sinAccion
		>
			<div className="detalle-credito">
				{
					administracion ?
						<>
							<h4>Usuario</h4>
							<p style={{ paddingLeft: '12px', marginBottom: '12px' }}>{credito.usuario?.nombre}</p>
						</>
						: null
				}

				<h4>Historial</h4>
				<div className="historial">
					<div className="historico">
						<span>Monto:</span>
						<span>Saldo Inicial:</span>
						<span>Interes:</span>
						<span>Total:</span>

						{
							credito.historial?.map(historico => (
								<>
									<p>{monedaFormato(historico.monto)}</p>
									<p>{monedaFormato(historico.pendiente_inicio)}</p>
									<p>{historico.interes}%</p>
									<p>{monedaFormato(historico.total)}</p>
								</>
							))
						}

						<p>{monedaFormato(credito.monto)}</p>
						<p>{monedaFormato(credito.pendiente_inicio)}</p>
						<p>{credito.interes}%</p>
						<p>{monedaFormato(credito.total)}</p>
					</div>
				</div>

				<h4>Estado</h4>
				<div className="informacion">
					<div>
						<span>Fecha Inicio:</span>
						<p>{dateAFechaEspañol(credito.fecha_aceptacion)}</p>
					</div>
					<div>
						<span>Saldo:</span>
						<p style={{ fontWeight: 'bold' }}>{monedaFormato(credito.saldo)}</p>
					</div>
				</div>
			</div>

			{
				!administracion ?
					<>
						<h4 style={{ marginBottom: '12px' }}>Recordatorio</h4>
						<Formik
							initialValues={{ diaRecordatorio: credito.dia_recordatorio || '' }}
							onSubmit={editar}
						>
							<Form style={{ paddingLeft: '12px' }}>
								<Input
									nombre="diaRecordatorio"
									placeholder="Día del Mes para Realizar el Recordatorio de Pago"
									etiqueta="Cambiar día de Recordatorio:"
									tipo="number"
									min="1"
									max="31"
									step="1"
									required
								/>
								<Boton texto="Guardar" pequeño formulario />
							</Form>
						</Formik>

					</>
					: null
			}

		</Modal>
	)

}

export default ModalDetallesCredito