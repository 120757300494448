import Boton from "../Boton/Boton"
import Input from "../Input"
import { ToastContainer } from "react-toastify"
import {  useNavigate } from 'react-router-dom'
import { Formik, Form } from "formik"
import Notificacion from "../../helpers/Notificacion"
import { axiosInstance } from "../../helpers/axios"

const estilos = {
	titulo: {
		textAlign: 'center'
	},
	boton: {
		margin: '0 auto'
	}
}

const submit = (valores, navigate) => {
	// Desactivar botón de login
	const boton = document.querySelector('.formulario input[type="submit"]')
	boton.disabled = true

	// Mostar notificacion
	const idNotificacion = Notificacion.cargando('Validando Datos...')

	fetch(`${process.env.REACT_APP_URI}/login`, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			'accept': 'application/json'
		},
		body: JSON.stringify({
			correo: valores.correo,
			contraseña: valores.contraseña
		})
	})
		.then(response => response.json())
		.then(respuesta => {
			if (respuesta.success) {
				console.log(respuesta.data)
				localStorage.setItem('id', respuesta.data.id)
				localStorage.setItem('token', respuesta.data.token)
				localStorage.setItem('nombre', respuesta.data.nombre)
				localStorage.setItem('rol', respuesta.data.rol)
				axiosInstance.defaults.headers['Authorization'] = `Bearer ${respuesta.data.token}`
				navigate('/dashboard', { replace: true })
			} else
				Notificacion.error(idNotificacion, 'Datos Erroneos', () => boton.disabled = false)
		})
		.catch(err => console.error(err))
}

const Formulario = () => {
	const navigate = useNavigate()

	return (
		<div className="login-formulario">
			<div>
				<img alt="Logo" src="/assets/Selfish-logo.svg" width={150} />
				<h4 style={estilos.titulo}>INTRANET</h4>
			</div>

			<div className="bienvenida">
				<h3>Bienvenido</h3>
				<h5>Inicia Sesión</h5>
			</div>

			<Formik
				initialValues={{ correo: '', contraseña: '' }}
				onSubmit={valores => submit(valores, navigate)}
			>
				<Form className="formulario">
					<Input
						nombre="correo"
						etiqueta="CORREO"
						placeholder="Correo Selfish"
						required
						autocomplete
					/>
					<Input
						nombre="contraseña"
						tipo="password"
						etiqueta="CONTRASEÑA"
						placeholder="Contraseña"
						required
						contraseña
					/>
					<Boton estilo={estilos.boton} texto="INICIAR SESIÓN" formulario noFocus />
				</Form>

			</Formik>

			<ToastContainer />
		</div>
	)

}

export default Formulario