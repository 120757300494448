import { toast, Slide } from "react-toastify"
import { Confirm } from "../components/Confirmacion"

const propiedades = {
	isLoading: false,
	autoClose: 3000,
	closeOnClick: true
}

const Notificacion = {
	cargando: texto => toast.loading(texto, { transition: Slide }),
	exito: (id, texto) => {
		toast.update(id, {
			render: texto,
			type: toast.TYPE.SUCCESS,
			...propiedades
		})
	},
	error: (id, texto, callback) => {
		toast.update(id, {
			render: texto,
			type: toast.TYPE.ERROR,
			...propiedades,
			onClose: callback
		})
	},
	confirmacion: (mensaje, exito, cancelar = undefined) => {
		toast.warning(<Confirm {...{ mensaje, exito, cancelar }} />, {
			isLoading: false,
			autoClose: 6000,
			closeOnClick: false,
			closeButton: false,
			draggable: false,
			icon: false
		})
	},
}

export default Notificacion