import Modal from "../Modal/Modal"
import Input from "../Input"
import { Formik, Form } from "formik"


const ModalModificarAccesoCPN = ({ modal, setModal, acceso, modificar }) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Modificar Accesos del cPanel"
			idFormulario="modificar_anuncioCPN"
			textoBoton="Modificar"
		>
			<Formik
				initialValues={{
					enlace: acceso.enlace,
					usuario: acceso.usuario,
					contraseña: acceso.contraseña
				}}
				onSubmit={valores => modificar(valores, acceso.id)}
			>
				<Form id='modificar_anuncioCPN'>
					<Input
						nombre="enlace"
						etiqueta="Enlace:"
						placeholder="URL para Acceder al cPanel"
					/>
					<Input
						nombre="usuario"
						etiqueta="Usuario:"
						placeholder="Usuario para Ingresar al cPanel"
					/>
					<Input
						nombre="contraseña"
						etiqueta="Contraseña:"
						placeholder="Contraseña para Ingresar al cPanel"
					/>
				</Form>
			</Formik>
		</Modal>
	)

}

export default ModalModificarAccesoCPN