import { useEffect, useState } from 'react'
import Table from '../Table'
import { ToastContainer } from "react-toastify"
import usePaginacion from '../../hooks/usePaginacion'
import useAnuncios from '../../hooks/useAnuncios'
import Boton from '../Boton/Boton'
import BusquedaAnuncios from './BusquedaAnuncios'
import ModalPublicarAnuncio from './ModalPublicarAnuncio'
import ModalModificarAnuncio from './ModalModificarAnuncio'
import Notificacion from '../../helpers/Notificacion'
import BotonSuperior from '../Boton/BotonSuperior'
import Encabezado from '../Encabezado'
import { dateAFechaEspañol } from '../../helpers/Formato'
import Cargando from '../Cargando'
import ModalFijarDia from './ModalFijarDia'
import { meses } from '../../helpers/Variables'

const Anuncios = ({ title, encabezado, frase, fondo }) => {
	document.title = title
	const [{ cargandoAnuncios, anuncios }, getAnuncios, buscarAnuncios, crearAnuncio, desactivarAnuncio, activarAnuncio, modificarAnucio] = useAnuncios()
	const [modalPublicar, setModalPublicar] = useState(false)
	const [modalModificar, setModalModificar] = useState(false)
	const [anuncioModificar, setAnuncioModificar] = useState({})
	const [pagina, setPagina, porPagina] = usePaginacion(10)
	const filasVacias = pagina > 0 ? Math.max(0, (1 + pagina) * porPagina - anuncios.length) : 0
	const [modalFijar, setModalFijar] = useState(false)
	const [festivos, setFestivos] = useState(false)

	// eslint-disable-next-line
	useEffect(getAnuncios, [])

	const crear = valores => {
		const idNotificacion = Notificacion.cargando('Publicando Anuncio...')

		crearAnuncio(valores)
			.then(respuesta => {
				if (respuesta === 1) {
					Notificacion.exito(idNotificacion, 'Anuncio Publicado')
					document.querySelector('.busqueda input[type="submit"]').click()
					setModalPublicar(false)
					setModalFijar(false)
				} else
					Notificacion.error(idNotificacion, 'Ocurrió un Error')
			})
			.catch(err => console.error(err))
	}

	const modificar = (valores, id) => {
		const idNotificacion = Notificacion.cargando(`Modificando ${festivos ? 'Día Festivo' : 'Anuncio'}...`)

		modificarAnucio(valores, id)
			.then(respuesta => {
				if (respuesta === 1) {
					Notificacion.exito(idNotificacion, `${festivos ? 'Día Festivo' : 'Anuncio'} Modificado`)
					document.querySelector('.busqueda input[type="submit"]').click()
					setModalModificar(false)
				} else
					Notificacion.error(idNotificacion, 'Ocurrió un Error')
			})
			.catch(err => console.error(err))
	}

	const desactivar = idAnuncio => {
		const idNotificacion = Notificacion.cargando(`Desactivando ${festivos ? 'Día Festivo' : 'Anuncio'}...`)

		desactivarAnuncio(idAnuncio)
			.then(respuesta => {
				if (respuesta === 1) {
					Notificacion.exito(idNotificacion, `${festivos ? 'Día Festivo' : 'Anuncio'} Desactivado`)
					document.querySelector('.busqueda input[type="submit"]').click()
				} else
					Notificacion.error(idNotificacion, 'Ocurrió un Error')
			})
	}

	const activar = idAnuncio => {
		const idNotificacion = Notificacion.cargando(`Activando ${festivos ? 'Día Festivo' : 'Anuncio'}...`)

		activarAnuncio(idAnuncio, festivos)
			.then(respuesta => {
				if (respuesta === 1) {
					Notificacion.exito(idNotificacion, `${festivos ? 'Día Festivo' : 'Anuncio'} Activado`)
					document.querySelector('.busqueda input[type="submit"]').click()
				} else
					Notificacion.error(idNotificacion, 'Ocurrió un Error')
			})
	}

	return (
		<div className='layout-contenido'>

			<img className="layout-fondo" src={fondo} alt="fondo" />

			<Encabezado titulo={encabezado} frase={frase} />

			<BotonSuperior texto="Publicar Anuncio" accion={() => setModalPublicar(true)} >
				<button className='boton no-focus' onClick={() => setModalFijar(true)} >
					Fijar Día Festivo
				</button>
			</BotonSuperior>

			<BusquedaAnuncios buscar={val => {
				setPagina(0)
				if (val.festivo === '1')
					setFestivos(true)
				else
					setFestivos(false)
				buscarAnuncios(val)
			}} />

			<Table
				encabezado={['Título', `${festivos ? 'Dia' : 'Descripción'}`, `${festivos ? 'Mes' : 'Fecha Creación'}`, 'Estado', 'Acciones']}
				porPagina={porPagina}
				pagina={pagina}
				setPagina={setPagina}
				total={anuncios.length}
				filasVacias={filasVacias}
			>
				{
					cargandoAnuncios ? <Cargando tabla />
						: (anuncios.slice(pagina * porPagina, pagina * porPagina + porPagina)).map(anuncio => (
							<tr key={anuncio.idAnuncio}>
								<td className='mw-1'>{anuncio.titulo}</td>
								<td className='mw-2'>{festivos ? anuncio.dia : anuncio.descripcion}</td>
								<td className='mw-1'>{festivos ? meses[anuncio.mes - 1] : dateAFechaEspañol(anuncio.fechaCreacion)}</td>
								<td>
									{anuncio.activo === 1 ? 'ACTIVO' : 'INACTIVO'}
								</td>
								<td>
									<div className='botones-acciones'>
										{
											anuncio.activo === 1 ?
												<Boton texto="Desactivar" pequeño noFocus accion={() => desactivar(anuncio.idAnuncio)} />
												:
												<Boton texto="Activar" pequeño noFocus accion={() => activar(anuncio.idAnuncio)} />
										}
										{
											<Boton texto="Modificar" pequeño noFocus accion={() => { setAnuncioModificar(anuncio); setModalModificar(true) }} />
										}
									</div>
								</td>
							</tr>
						))
				}
			</Table>

			<ModalPublicarAnuncio modal={modalPublicar} setModal={setModalPublicar} crear={crear} />

			<ModalModificarAnuncio modal={modalModificar} setModal={setModalModificar} anuncio={anuncioModificar} modificar={modificar} />

			<ModalFijarDia modal={modalFijar} setModal={setModalFijar} crear={crear} />

			<ToastContainer />
		</div>
	)

}

export default Anuncios