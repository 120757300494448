import Boton from "./Boton/Boton"
import "./confirmacion.css"


export const Confirm = ({
    mensaje,
    exito,
    cancelar,
    closeToast
}) => {

    const handleSuccess = () => {
        exito()
        closeToast && closeToast()
    }

    const handleCancel = () => {
        cancelar && cancelar()
        closeToast && closeToast()
    }

    return (
        <div className="confirm-toast--sf">
            <p>{mensaje}</p>
            <div className="confirm-toast-buttons--sf">
                <Boton texto="Cancelar" accion={handleCancel} pequeño />
                <Boton texto="Eliminar" accion={handleSuccess} pequeño />
            </div>
        </div >
    )

}
