import Modal from "../Modal/Modal"
import { Formik, Form } from "formik"
import Input from "../Input"


const ModalRegistrarAbono = ({ modal, setModal, registrar, saldo }) => {

	const hoy = new Date()
	const fecha = `${hoy.getFullYear()}-${('00' + (hoy.getMonth() + 1)).slice(-2)}-${('00' + hoy.getDate()).slice(-2)}`

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Registrar Abono"
			idFormulario="registrar_abono"
			textoBoton="Abonar"
		>
			<Formik
				initialValues={{ abono: '', fecha }}
				onSubmit={registrar}
			>
				<Form id="registrar_abono">
					<Input
						nombre="abono"
						placeholder="Cantidad a Abonar"
						etiqueta="Monto:*"
						tipo="number"
						required
						min="0"
						max={saldo}
						step="0.01"
					/>
					<Input
						nombre="fecha"
						etiqueta="Fecha:*"
						tipo="date"
						required
					/>
				</Form>
			</Formik>

		</Modal>
	)

}

export default ModalRegistrarAbono