import './botonNav.css'
import { NavLink } from 'react-router-dom'

const BotonNav = ({ texto, link, icono, accion }) => {

	return (
		<NavLink to={link} onClick={accion} className="boton-nav">
			{icono}
			{texto}
		</NavLink>
	)

}

export default BotonNav