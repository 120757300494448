const DashboardIcon = (props) => (
	<svg
		width="1em"
		height="1em"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		{...props}
	>
		<path
			fill="#999"
			d="M.029 15h9v5h-9zm11-15h9v5h-9zm0 7h9v13h-9zm-11-7h9v13h-9z"
		/>
	</svg>
)

export default DashboardIcon