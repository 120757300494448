import Modal from "../Modal/Modal"
import { Formik, Form } from "formik"
import Input from "../Input"


const ModalCrearSolicitud = ({
	modal,
	setModal,
	crear,
	creditoExistente
}) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Solicitar Préstamo"
			idFormulario="crear_solicitud"
			textoBoton="Solicitar"
		>

			{creditoExistente && (
				<small style={{
					display: 'block',
					marginBottom: '12px',
					color: '#999'
				}}>
					Debido a que tienes un crédito activo, el interés de la nueva solicitud aumentará un 3%.
				</small>
			)}

			<Formik
				initialValues={{ monto: '' }}
				onSubmit={crear}
			>
				<Form id="crear_solicitud">
					<Input
						nombre="monto"
						placeholder="Monto solicitado"
						etiqueta="Monto:*"
						tipo="number"
						required
						min="0"
						step="0.01"
					/>
				</Form>
			</Formik>

		</Modal>
	)

}

export default ModalCrearSolicitud