import { useState } from "react"
import useCreditosAdministracion from "../../hooks/useCreditosAdministracion"
import usePaginacion from "../../hooks/usePaginacion"
import Boton from "../Boton/Boton"
import Cargando from "../Cargando"
import Table from "../Table"
import Credito from "./Credito"
import ModalAbonosCredito from "./ModalAbonosCredito"
import ModalDetallesCredito from "./ModalDetallesCredito"
import ModalRegistrarAbono from "./ModalRegistrarAbono"
import { mensajesMutation } from "../../helpers/Variables"
import { useQueryClient } from "@tanstack/react-query"
import ModalCancelarSolicitud from "./ModalCancelarSolicitud"
import { dateAFechaEspañol, monedaFormato } from "../../helpers/Formato"
import ModalAceptarSolicitud from "./ModalAceptarSolicitud"


const CreditosAdministracion = () => {

	const { useGetCreditos, useGetCreditosLiquidados, useGetSolicitudes, useCancelarSolicitud, useRegistrarAbono, useAceptarSolicitud } = useCreditosAdministracion([])
	const queryClient = useQueryClient()
	const creditosQuery = useGetCreditos()
	const creditosLiquidadosQuery = useGetCreditosLiquidados()
	const solicitudesQuery = useGetSolicitudes()

	const aceptarSolicitud = useAceptarSolicitud(mensajesMutation({
		cargando: 'Aceptando Solicitud...',
		exito: 'Solicitud de Crédito Aceptada',
		error: 'Ocurrió un Error'
	}))

	const cancelarSolicitud = useCancelarSolicitud(mensajesMutation({
		cargando: 'Cancelando Solicitud...',
		exito: 'Solicitud de Crédito Cancelada',
		error: 'Ocurrió un Error'
	}))

	const registrarAbono = useRegistrarAbono(mensajesMutation({
		cargando: 'Registrando Abono...',
		exito: 'Abono Registrado',
		error: 'Ocurrió un Error'
	}))

	const [paginaSolicitudes, setPaginaSolicitudes, porPaginaSolicitudes] = usePaginacion(4)
	const filasVaciasSolicitudes = paginaSolicitudes > 0 ? Math.max(0, (1 + paginaSolicitudes) * porPaginaSolicitudes - (solicitudesQuery.data?.length)) : 0
	const [paginaCreditosLiquidados, setPaginaCreditosLiquidados, porPaginaCreditosLiquidados] = usePaginacion(4)
	const filasVaciasCreditosLiquidados = paginaCreditosLiquidados > 0 ? Math.max(0, (1 + paginaCreditosLiquidados) * porPaginaCreditosLiquidados - (creditosLiquidadosQuery.data?.length)) : 0
	const [credito, setCredito] = useState({})
	const [modalDetalles, setModalDetalles] = useState(false)
	const [modalAbonos, setModalAbonos] = useState(false)
	const [modalRegistrarAbono, setModalRegistrarAbono] = useState(false)
	const [modalCancelar, setModalCancelar] = useState({ abierto: false, idSolicitud: 0 })
	const [modalAceptar, setModalAceptar] = useState({ abierto: false, idSolicitud: 0 })


	const handleCancelarSolicitud = (id, datos) => {
		cancelarSolicitud.mutate({ id, datos }, {
			onSuccess: () => {
				queryClient.invalidateQueries(['creditos', 'solicitudes'])
				setModalCancelar({ abierto: false, idSolicitud: 0 })
			}
		})
	}

	const handleRegistrarAbono = (id, valores) => {
		registrarAbono.mutate({ id, valores }, {
			onSuccess: () => {
				queryClient.invalidateQueries(['creditos'])
				setModalRegistrarAbono(false)
			}
		})
	}

	const handleAceptarSolicitud = (id, datos = undefined) => {
		aceptarSolicitud.mutate({ id, datos }, {
			onSuccess: () => {
				queryClient.invalidateQueries(['creditos'])
				setModalAceptar({ abierto: false, idSolicitud: 0 })
			}
		})
	}

	return (
		<section id="creditos_administracion">
			<h3>Administración de Créditos</h3>

			<h4>Solicitudes</h4>
			<Table
				encabezado={["Usuario", "Monto", "Acciones"]}
				porPagina={porPaginaSolicitudes}
				pagina={paginaSolicitudes}
				setPagina={setPaginaSolicitudes}
				total={solicitudesQuery.data?.length || 0}
				filasVacias={filasVaciasSolicitudes}
				noFixed
			>

				{
					solicitudesQuery.isLoading ? <Cargando tabla />
						: (solicitudesQuery.data?.slice(paginaSolicitudes * porPaginaSolicitudes, paginaSolicitudes * porPaginaSolicitudes + porPaginaSolicitudes)).map(solicitud => (
							<tr key={solicitud.id}>
								<td>{solicitud.usuario.nombre}</td>
								<td>{monedaFormato(solicitud.monto)}</td>
								<td>
									<div className="botones-acciones">
										<Boton
											texto="Aceptar"
											pequeño
											accion={() => {
												// Si el usuario no tiene créditos activos se solicita el interés inicial
												if (creditosQuery.data?.findIndex(credito => credito.usuario.id === solicitud.usuario.id) === -1)
													setModalAceptar({ abierto: true, idSolicitud: solicitud.id })
												else
													handleAceptarSolicitud(solicitud.id)
											}} noFocus />
										<Boton texto="Cancelar" pequeño accion={() => setModalCancelar({ abierto: true, idSolicitud: solicitud.id })} noFocus />
									</div>
								</td>
							</tr>
						))
				}
			</Table>

			<h4>Créditos Activos</h4>
			<div className="tarjetas">
				{
					creditosQuery.isLoading ? <Cargando />
						: creditosQuery.data?.map(credito => (
							<Credito
								key={credito.id}
								credito={credito}
								detalles={() => { setCredito(credito); setModalDetalles(true) }}
								abonos={() => { setCredito(credito); setModalAbonos(true) }}
								administracion
								registrar={() => { setCredito(credito); setModalRegistrarAbono(true) }}
							/>
						))
				}
			</div>

			<h4>Créditos Liquidados</h4>
			<Table
				encabezado={["Usuario", "Total de Crédito", "Fecha de Inicio", "Fecha de Liquidación"]}
				porPagina={porPaginaCreditosLiquidados}
				pagina={paginaCreditosLiquidados}
				setPagina={setPaginaCreditosLiquidados}
				total={creditosLiquidadosQuery.data?.length || 0}
				filasVacias={filasVaciasCreditosLiquidados}
				noFixed
			>

				{
					creditosLiquidadosQuery.isLoading ? <Cargando tabla />
						: (creditosLiquidadosQuery.data?.slice(paginaCreditosLiquidados * porPaginaCreditosLiquidados, paginaCreditosLiquidados * porPaginaCreditosLiquidados + porPaginaCreditosLiquidados)).map(credito => (
							<tr key={credito.id}>
								<td>{credito.usuario.nombre}</td>
								<td>{monedaFormato(credito.total)}</td>
								<td>{dateAFechaEspañol(credito.fecha_aceptacion)}</td>
								<td>{dateAFechaEspañol(credito.fecha_liquidacion)}</td>
							</tr>
						))
				}
			</Table>

			{
				credito.id &&
				<ModalAbonosCredito
					modal={modalAbonos}
					setModal={setModalAbonos}
					idCredito={credito.id}
					saldoCredito={credito.saldo}
					administracion
				/>
			}

			<ModalDetallesCredito
				modal={modalDetalles}
				setModal={setModalDetalles}
				credito={credito}
				editar={null}
				administracion
			/>

			<ModalRegistrarAbono
				modal={modalRegistrarAbono}
				setModal={setModalRegistrarAbono}
				registrar={valores => handleRegistrarAbono(credito.id, valores)}
				saldo={credito.saldo}
			/>

			<ModalCancelarSolicitud
				modal={modalCancelar.abierto}
				setModal={val => setModalCancelar({ abierto: val, idSolicitud: 0 })}
				cancelar={val => handleCancelarSolicitud(modalCancelar.idSolicitud, val)}
			/>

			<ModalAceptarSolicitud
				modal={modalAceptar.abierto}
				setModal={val => setModalAceptar({ abierto: val, idSolicitud: 0 })}
				aceptar={val => handleAceptarSolicitud(modalAceptar.idSolicitud, val)}
			/>
		</section>
	)

}

export default CreditosAdministracion