import { useMutation, useQuery } from "@tanstack/react-query"
import { axiosInstance } from "./../helpers/axios"


const useCreditos = () => {

	// Obtener todos los creditos
	const useGetCredito = () => {
		return useQuery(
			['creditos', 'personal'],
			async () => (await axiosInstance.get('/creditos/usuario')).data
		)
	}

	// Obtener todas las solicitudes
	const useGetSolicitudes = () => {
		return useQuery(
			['creditos', 'solicitudes', 'personal'],
			async () => (await axiosInstance.get('/creditos/solicitudes/usuario')).data
		)
	}

	// Crear una nueva solicitud
	const useCrearSolicitud = mensajes => {
		return useMutation(
			['creditos', 'solicitudes', 'personal', 'crear'],
			async datos => (await axiosInstance.post('/creditos/solicitudes', datos)).data,
			mensajes
		)
	}

	// Modificar dia de recordatorio
	const useModificarRecordatorio = mensajes => {
		return useMutation(
			['creditos', 'personal', 'diaRecordatorio'],
			async ({ id, datos }) => (await axiosInstance.put(`/creditos/${id}/modificarDiaRecordatorio`, datos)).data,
			mensajes
		)
	}

	// Obtener todos los abonos
	const useGetAbonos = id => {
		return useQuery(
			['creditos', id, 'abonos'],
			async () => (await axiosInstance.get(`/creditos/${id}/abonos`)).data
		)
	}

	return { useGetCredito, useGetSolicitudes, useCrearSolicitud, useModificarRecordatorio, useGetAbonos }

}

export default useCreditos