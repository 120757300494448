import { Field } from "formik"

const Select = ({ children, nombre, etiqueta }) => {

	return (
		<div className="select">
			<label>{etiqueta}</label>
			<span>
				<Field
					as="select"
					name={nombre}
				>
					{children}
				</Field>
			</span>
		</div>
	)

}

export default Select