const CloseIcon = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="0 0 20 20"
		{...props}
	>
		<path
			d="M1.645 1.645a1.69 1.69 0 0 1 2.39 0L10.01 7.62l5.976-5.976a1.69 1.69 0 0 1 2.39 2.39L12.4 10.01l5.976 5.976a1.69 1.69 0 0 1-2.39 2.39L10.01 12.4l-5.976 5.976a1.69 1.69 0 0 1-2.39-2.39l5.977-5.976-5.976-5.976a1.69 1.69 0 0 1 0-2.39Z"
			fill="#999"
		/>
	</svg>
)

export default CloseIcon
