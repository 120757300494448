const CrediSelfish = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="0 0 23 17"
		{...props}
	>
		<path
			d="M0 2.833C0 2.082.303 1.361.842.83 1.382.299 2.112 0 2.875 0h17.25c.762 0 1.494.299 2.033.83.54.531.842 1.252.842 2.003v7.084H0V2.833ZM16.531 4.25a.724.724 0 0 0-.508.207.703.703 0 0 0-.21.501v1.417c0 .188.075.368.21.5a.724.724 0 0 0 .508.208h2.875c.19 0 .374-.074.509-.207a.703.703 0 0 0 .21-.501V4.958a.703.703 0 0 0-.21-.5.724.724 0 0 0-.509-.208h-2.875ZM0 12.75v1.417c0 .751.303 1.472.842 2.003.54.531 1.27.83 2.033.83h17.25c.762 0 1.494-.299 2.033-.83.54-.531.842-1.252.842-2.003V12.75H0Z"
			fill="#999"
		/>
	</svg>
)

export default CrediSelfish
