import { useState } from "react"
import { cerrarSesion } from "../helpers/Variables"


const useVacaciones = () => {

	const [vacaciones, setVacaciones] = useState([])
	const [disponibles, setDisponibles] = useState(0)
	const [solicitudes, setSolicitudes] = useState({ cargandoSolicitudes: true, solicitudes: [] })
	const [solicitudesUser, setSolicitudesUser] = useState({ cargandoSolicitudesUser: true, solicitudesUser: [] })
	const [user, setSingleUser] = useState({ cargandoUser: true, user: [] })
	const [users, setUsers] = useState({ cargandoUsuarios: true, users: [] })

	// Obtener todas las solicitudes de vacaciones del usuario
	const getSolicitudesUsuario = () => {
		fetch(`${process.env.REACT_APP_URI}/vacaciones/usuario`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
			.then(response => {
				if (response.status === 401)
					cerrarSesion()
				return response.json()
			})
			.then(respuesta => setSolicitudes({ cargandoSolicitudes: false, solicitudes: respuesta }))
			.catch(err => console.error(err))
	}

	// Obtener todas las solicitudes de vacaciones de todos los usuarios
	const getTodasSolicitudes = () => {
		fetch(`${process.env.REACT_APP_URI}/vacaciones/solicitudes`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
			.then(response => {
				if (response.status === 401)
					cerrarSesion()
				return response.json()
			})
			.then(respuesta => setSolicitudes({ cargandoSolicitudes: false, solicitudes: respuesta }))
			.catch(err => console.error(err))
	}

	// Obtener todos los días que ya tienen vacaciones activas
	const getTodasVacacionesActivas = () => {
		fetch(`${process.env.REACT_APP_URI}/vacaciones/activas`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
			.then(response => {
				if (response.status === 401)
					cerrarSesion()
				return response.json()
			})
			.then(respuesta => setVacaciones(respuesta))
			.catch(err => console.error(err))
	}

	// Crear una nueva solicitud
	const crearSolicitud = async datos => {
		const response = await fetch(`${process.env.REACT_APP_URI}/vacaciones/crearSolicitud`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
				'accept': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({
				dias: datos.fechas.length,
				fechas: datos.fechas.map(fecha => fecha.fecha).join(';')
			})
		})
		if (response.status === 401)
			cerrarSesion()
		return await response.json()
	}

	// Cancelar Solicitud
	const cancelarSolicitud = async (id, mensaje_to = 'Cancelada por el usuario') => {
		const response = await fetch(`${process.env.REACT_APP_URI}/vacaciones/cancelarSolicitud/${id}`, {
			method: 'PUT',
			headers: {
				'content-type': 'application/json',
				'accept': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({
				mensaje: mensaje_to
			})
		})
		if (response.status === 401)
			cerrarSesion()
		return await response.json()
	}

	// Aceptar Solicitud
	const aceptarSolicitud = async id => {
		const response = await fetch(`${process.env.REACT_APP_URI}/vacaciones/aceptarSolicitud/${id}`, {
			method: 'PUT',
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})

		if (response.status === 401)
			cerrarSesion()
		return await response.json()
	}

	const getDisponibles = async => {
		// Obtener todas las solicitudes de vacaciones del usuario
		fetch(`${process.env.REACT_APP_URI}/vacaciones/disponibles`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
			.then(response => {
				if (response.status === 401)
					cerrarSesion()
				return response.json()
			})
			.then(respuesta => setDisponibles(respuesta))
			.catch(err => console.error(err))
	}

	const getUsers = async => {
		fetch(`${process.env.REACT_APP_URI}/usuarios`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
			.then(response => {
				if (response.status === 401)
					cerrarSesion()
				return response.json()
			})
			.then(respuesta => {
				// Crear un arreglo de promesas para obtener total_dias para cada usuario
				const promises = respuesta.map(async (user) => {
					const total_dias = (await getUtilizados(user.id)).total_dias;
					return { ...user, total_dias }; // Agregar total_dias al objeto user
				});

				// Esperar a que todas las promesas se resuelvan
				Promise.all(promises)
					.then(updatedUsers => {
						// Actualizar el estado con los usuarios actualizados
						setUsers({
							cargandoUsuarios: false,
							users: updatedUsers,
						});
					})
					.catch(error => {
						// Manejar errores si es necesario
					});
			})
			.catch(err => console.error(err))
	}

	const getUtilizados = async id => {
		const response = await fetch(`${process.env.REACT_APP_URI}/vacaciones/getUtilizados/${id}`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
		if (response.status === 401)
			cerrarSesion()
		return await response.json()
	}

	const getSingleUser = async id => {
		fetch(`${process.env.REACT_APP_URI}/usuarios/query_user/${id}`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
			.then(response => {
				if (response.status === 401) cerrarSesion();
				return response.json();
			})
			.then(user => {
				// Obtener total_dias para el usuario actual
				getUtilizados(user.id)
					.then(utilizados => {
						// Agregar total_dias al objeto user
						const userConTotalDias = { ...user, total_dias: utilizados.total_dias };

						// Actualizar el estado con el usuario actualizado
						setSingleUser({
							cargandoUser: false,
							user: userConTotalDias
						});
					})
					.catch(error => {
						// Manejar errores si es necesario
					});
			})
			.catch(err => console.error(err));
	}

	const getSolicitudesPerUser = async id => {
		await fetch(`${process.env.REACT_APP_URI}/vacaciones/usuario/${id}`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
			.then(response => {
				if (response.status === 401)
					cerrarSesion()
				return response.json()
			})
			.then(respuesta => setSolicitudesUser({ cargandoSolicitudesUser: false, solicitudesUser: respuesta }))
			.catch(err => console.error(err))
	}

	return {
		vacaciones,
		getTodasVacacionesActivas,
		solicitudes,
		getSolicitudesUsuario,
		crearSolicitud,
		cancelarSolicitud,
		getTodasSolicitudes,
		aceptarSolicitud,
		disponibles,
		getDisponibles,
		setDisponibles,
		getUsers,
		users,
		getUtilizados,
		getSingleUser,
		user,
		getSolicitudesPerUser,
		solicitudesUser
	}

}

export default useVacaciones