import Modal from "../Modal/Modal"
import Input from "../Input"
import { Formik, Form } from "formik"


const ModalModificarAccesoDB = ({ modal, setModal, acceso, modificar }) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Modificar Accesos de la Base de Datos"
			idFormulario="modificar_accesoDB"
			textoBoton="Modificar"
		>
			<Formik
				initialValues={{
					nombre: acceso.nombre,
					usuario: acceso.usuario,
					contraseña: acceso.contraseña
				}}
				onSubmit={valores => modificar(valores, acceso.id)}
			>
				<Form id='modificar_accesoDB'>
					<Input
						nombre="nombre"
						etiqueta="Nombre:"
						placeholder="Nombre de la Base de Datos"
					/>
					<Input
						nombre="usuario"
						etiqueta="Usuario:"
						placeholder="Usuario de la Base de Datos"
					/>
					<Input
						nombre="contraseña"
						etiqueta="Contraseña:"
						placeholder="Contraseña de la Base de Datos"
					/>
				</Form>
			</Formik>
		</Modal>
	)

}

export default ModalModificarAccesoDB