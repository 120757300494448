import Input from "../Input"
import Modal from "../Modal/Modal"
import { Formik, Form } from "formik"
import UsuarioWordPress from "./UsuariosWordPress"


const ModalAgregarAccesos = ({ modal, setModal, crear }) => {

	return (
		<Modal
			open={modal}
			cerrar={() => { setModal(false) }}
			titulo="Agregar Accesos"
			idFormulario="crear_acceso"
			textoBoton="Agregar"
			classExtra="modal-accesos"
		>
			<Formik
				initialValues={{
					pagina: '',
					wp: [
						{ usuario: '', contraseña: '' }
					],
					db: {
						nombre: '',
						usuario: '',
						contraseña: ''
					},
					cpanel: {
						enlace: '',
						usuario: '',
						contraseña: ''
					}
				}}
				onSubmit={crear}
			>
				{({ values }) => (
					<Form id='crear_acceso'>
						<h4>Página Web</h4>
						<div className="sangria">
							<Input
								nombre="pagina"
								placeholder="URL de la Página"
								etiqueta="Enlace:*"
								required
							/>
						</div>

						<UsuarioWordPress
							usuarios={values.wp}
						/>

						<h4>Accesos al cPanel</h4>
						<div className="sangria">
							<Input
								nombre="cpanel.enlace"
								etiqueta="Enlace:"
								placeholder="URL al cPanel de la Página"
							/>
							<div className="dos-columnas">
								<Input
									nombre="cpanel.usuario"
									etiqueta="Usuario:"
									placeholder="Usuario del cPanel"
								/>
								<Input
									nombre="cpanel.contraseña"
									etiqueta="Contraseña:"
									placeholder="Contraseña del cPanel"
								/>
							</div>
						</div>

						<h4>Accesos de la Base de Datos</h4>
						<div className="sangria">
							<Input
								nombre="db.nombre"
								etiqueta="Nombre:"
								placeholder="Nombre de la Base de Datos"
							/>
							<div className="dos-columnas">
								<Input
									nombre="db.usuario"
									etiqueta="Usuario:"
									placeholder="Usuario de la Base de Datos"
								/>
								<Input
									nombre="db.contraseña"
									etiqueta="Contraseña:"
									placeholder="Contraseña de la Base de Datos"
								/>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</Modal>
	)

}

export default ModalAgregarAccesos