import { useEffect, useState } from 'react'
import Notificacion from '../../helpers/Notificacion'
import usePaginacion from '../../hooks/usePaginacion'
import Boton from '../Boton/Boton'
import BotonSuperior from '../Boton/BotonSuperior'
import Table from '../Table'
import { ToastContainer } from "react-toastify"
import { rolAdministrador } from '../../helpers/Variables'
import useCorreos from '../../hooks/useCorreos'
import BusquedaCorreos from './BusquedaCorreos'
import ModalAgregarCorreo from './ModalAgregarCorreo'
import ModalModificarCorreo from './ModalModificarCorreo'
import Encabezado from '../Encabezado'
import Cargando from '../Cargando'

const Correos = ({ title, encabezado, frase, fondo }) => {
	document.title = title

	const [modalAgregar, setModalAgregar] = useState(false)
	const [modalModificar, setModalModificar] = useState(false)
	const [correoModificar, setCorreoModificar] = useState({})
	const [{ cargandoCorreos, correos }, getCorreos, buscarCorreos, crearCorreo, modificarCorreo] = useCorreos()
	const [pagina, setPagina, porPagina] = usePaginacion(10)
	const filasVacias = pagina > 0 ? Math.max(0, (1 + pagina) * porPagina - correos.length) : 0
	const rol = localStorage.getItem('rol')

	// eslint-disable-next-line
	useEffect(getCorreos, [])

	const crear = valores => {
		const idNotificacion = Notificacion.cargando('Agregando Correo...')

		crearCorreo(valores)
			.then(respuesta => {
				if (respuesta === 1) {
					Notificacion.exito(idNotificacion, 'Correo Agregado')
					document.querySelector('.busqueda input[type="submit"]').click()
					setModalAgregar(false)
				} else
					Notificacion.error(idNotificacion, 'Ocurrió un Error')
			})
			.catch(err => console.error(err))
	}

	const modificar = (valores, id) => {
		const idNotificacion = Notificacion.cargando('Modificando Correo...')

		modificarCorreo(valores, id)
			.then(respuesta => {
				if (respuesta === 1) {
					Notificacion.exito(idNotificacion, 'Correo Modificado')
					document.querySelector('.busqueda input[type="submit"]').click()
					setModalModificar(false)
				} else
					Notificacion.error(idNotificacion, 'Ocurrió un Error')
			})
			.catch(err => console.error(err))
	}

	return (
		<div className="layout-contenido">

			<img className="layout-fondo" src={fondo} alt="fondo" />

			<Encabezado titulo={encabezado} frase={frase} />

			{
				rol === rolAdministrador ?
					<BotonSuperior texto="Agregar Correo" accion={() => setModalAgregar(true)} />
					: null
			}

			<BusquedaCorreos buscar={val => { setPagina(0); buscarCorreos(val) }} />

			<Table
				encabezado={['Nombre', 'Correo', 'Contraseña', 'Acciones']}
				porPagina={porPagina}
				pagina={pagina}
				setPagina={setPagina}
				total={correos.length}
				filasVacias={filasVacias}
			>
				{
					cargandoCorreos ? <Cargando tabla />
						: (correos.slice(pagina * porPagina, pagina * porPagina + porPagina)).map(correo => (
							<tr key={correo.idCorreoInterno}>
								<td>{correo.nombrePersonal}</td>
								<td>{correo.correo}</td>
								<td>{correo.contraseña}</td>
								<td>
									<div className='botones-acciones'>
										{
											<Boton texto="Modificar" pequeño noFocus accion={() => { setCorreoModificar(correo); setModalModificar(true) }} />
										}
									</div>
								</td>
							</tr>
						))
				}
			</Table>

			<ModalAgregarCorreo modal={modalAgregar} setModal={setModalAgregar} crear={crear} />
			<ModalModificarCorreo modal={modalModificar} setModal={setModalModificar} correo={correoModificar} modificar={modificar} />

			<ToastContainer />
		</div>
	)

}

export default Correos