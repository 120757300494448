const LoginImg = () => {

	return (
		<div className="login-img">
			<img alt="Selfish" src="/assets/Collage.png" />
		</div>
	)

}

export default LoginImg