import { axiosInstance } from "../helpers/axios"
import { useMutation, useQuery } from "@tanstack/react-query"


const useAccesos = () => {

	// Obtener todos los accesos
	const useGetAccesos = (busqueda) => {
		return useQuery(
			['clientes', 'accesos', { busqueda }],
			async () => (await axiosInstance.get('/clientes/accesos', { params: { busqueda } })).data
		)
	}

	// Modificar acceso de WordPress
	const useModificarAccesosWP = (mensajes) => {
		return useMutation(
			['accesosWP', 'modificar'],
			async ({ datos, id }) => (await axiosInstance.put(`/accesosWP/multiple/${id}`, datos)).data,
			mensajes
		)
	}

	// Modificar acceso de cPanel
	const useModificarCPanel = () => {
		return useMutation(
			['accesosCPanel', 'modificar'],
			async ({ datos, id }) => (await axiosInstance.put(`/accesosCPanel/${id}`, datos)).data
		)
	}

	// Modificar acceso de base de datos
	const useModificarDB = () => {
		return useMutation(
			['accesosBD', 'modificar'],
			async ({ datos, id }) => (await axiosInstance.put(`/accesosBD/${id}`, datos)).data
		)
	}

	// Crear un nuevo acceso
	const useCrearAcceso = mensajes => {
		return useMutation(
			['clientes', 'accesos', 'crear'],
			async datos => (await axiosInstance.post('/clientes/accesos', datos)).data,
			mensajes
		)
	}

	// Modificar pagina
	const useModificarPagina = mensajes => {
		return useMutation(
			['clientes', 'modificar'],
			async ({ datos, id }) => (await axiosInstance.put(`/clientes/${id}`, datos)).data,
			mensajes
		)
	}

	// Eliminar accesos
	const useEliminarAccesos = mensajes => {
		return useMutation(
			['clientes', 'eliminar'],
			async id => (await axiosInstance.delete(`/clientes/${id}`)).data,
			mensajes
		)
	}

	return { useGetAccesos, useCrearAcceso, useModificarAccesosWP, useModificarCPanel, useModificarDB, useModificarPagina, useEliminarAccesos }

}

export default useAccesos