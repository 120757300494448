import { useEffect } from "react";
import Recurso from "../Recursos/Recurso";
import Anuncio from "./Anuncio";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./dashboard.css";
import "./dashboard.responsive.css";
import Encabezado from "../Encabezado";
import useRecursos from "../../hooks/useRecursos";
import useAnuncios from "../../hooks/useAnuncios";
import useUsuarios from "../../hooks/useUsuarios";
import useVacaciones from "../../hooks/useVacaciones";
import Cargando from "../Cargando";
import ReactTooltip from "react-tooltip";
import PastelIcon from "../Icons/PastelIcon"
import VacacionesIcon from "../Icons/VacacionesIcon"
const Dashboard = ({ title, frase, encabezado, fondo }) => {
    document.title = title;

    const [{ cargandoAnuncios, anuncios }, , buscarAnuncios] = useAnuncios();
    const [{ cargandoRecursos, recursos }, , , , obtenerRecientes] = useRecursos();
    const [{ cargandoUsuarios, usuarios }, getUsuarios] = useUsuarios();
   
    const {
        vacaciones,
        getTodasVacacionesActivas,
        
    } = useVacaciones();
    useEffect(() => {
        buscarAnuncios({ estado: 'activos', titulo: '' }, true);
        obtenerRecientes();
        getUsuarios();
        getTodasVacacionesActivas()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


   if (useVacaciones.length === 0) {
      console.log(vacaciones)
    }

 
    const calcularEdad = (fechaNacimiento) => {
        const hoy = new Date();
        const nacimiento = new Date(fechaNacimiento);
        let edad = hoy.getFullYear() - nacimiento.getFullYear();
        const diferenciaMeses = hoy.getMonth() - nacimiento.getMonth();
        if (diferenciaMeses < 0 || (diferenciaMeses === 0 && hoy.getDate() < nacimiento.getDate())) {
            edad--;
        }
        return edad;
    };


    const hoy = new Date();
    hoy.setDate(hoy.getDate() - 1)
    const cumpleanosHoy = usuarios.filter(usuario => {
       // console.log(usuario)
        const fechaCumple = new Date(usuario.cumpleaños)
        return fechaCumple.getDate() === hoy.getDate() && fechaCumple.getMonth() === hoy.getMonth()
    });


   // console.log(cumpleanosHoy)

    return (
        <div className="layout-contenido">
            <img className="layout-fondo" src={fondo} alt="fondo" />
            <Encabezado titulo={encabezado} frase={frase} />

            <div id="dashboard">
                <section id="anuncios">
                    <div class="contenedor-anuncios">
                        <h3>Anuncios</h3>
                        {cargandoAnuncios ? <Cargando /> : anuncios.map(anuncio => anuncio.festivo === 0 ? <Anuncio anuncio={anuncio} key={anuncio.idAnuncio} /> : null)}
                    </div>
                    <div class="contenedor-cumpleanos">
                        <h3>¡Cumpleaños de hoy!</h3>
                        {cargandoUsuarios ? <Cargando /> :
                            cumpleanosHoy.length > 0 ? (
                                cumpleanosHoy.map(usuario => (
                                    <div key={usuario.id}>
                                        {usuario.nombre} cumple {calcularEdad(usuario.cumpleaños)} años hoy.
                                    </div>
                                ))
                            ) : (
                                <div>No hay cumpleaños hoy.</div>
                            )
                        }
                    </div>
                </section>

                <section id="calendario">
    <h3>Calendario</h3>
    <Calendar
        locale="es-ES"
        tileClassName={({ date, view, activeStartDate }) => {
            if (view === 'month' && vacaciones.includes(date.toISOString().slice(0, 10))) {
                return 'vacaciones-activas'; // Asegúrate de definir esta clase en tu CSS
            }
            if (view === 'month' && date.getMonth() === activeStartDate.getMonth()) {
                const anunciosMostrar = anuncios.filter(anuncio => {
                    if (anuncio.fechaInicio === date.toISOString().slice(0, 10))
                        return true;
                    if (anuncio.mes === (date.getMonth() + 1) && anuncio.dia === date.getDate())
                        return true;
                    return false;
                });
                if (anunciosMostrar.length > 0)
                    return `${anunciosMostrar.some(anuncio => anuncio.festivo === 1) ? 'festivo' : 'con-anuncio'}`;
            }
        }}
        tileContent={({ date, view, activeStartDate }) => {
            if (view === 'month' && date.getMonth() === activeStartDate.getMonth()) {
                const anunciosMostrar = anuncios.filter(anuncio => {
                    if (anuncio.fechaInicio === date.toISOString().slice(0, 10))
                        return true;
                    if (anuncio.mes === (date.getMonth() + 1) && anuncio.dia === date.getDate())
                        return true;
                    return false;
                });

                const cumpleañosMostrar = usuarios.filter(usuario => {
                    const cumpleaños = new Date(usuario.cumpleaños);
                    const localDate = new Date(cumpleaños.getTime() + cumpleaños.getTimezoneOffset() * 60000);
                    return localDate.getDate() === date.getDate() && localDate.getMonth() === date.getMonth();
                });

                // Filtra las vacaciones que coinciden con la fecha actual
                const vacacionesMostrar = vacaciones.filter(vacacion => vacacion.fecha === date.toISOString().slice(0, 10));

                return (
                    <div>
                        {anunciosMostrar.length > 0 && (
                            <>
                                <span className="tooltip" data-for={`info.${anunciosMostrar[0].idAnuncio}`} data-tip data-event='click focus'></span>
                                <ReactTooltip className="contenido-tooltip" effect="solid" globalEventOff="click" id={`info.${anunciosMostrar[0].idAnuncio}`}>
                                    <ul>{anunciosMostrar.map(anuncio => <li key={anuncio.idAnuncio}>{anuncio.titulo}</li>)}</ul>
                                </ReactTooltip>
                            </>
                        )}
                        {cumpleañosMostrar.length > 0 && (
                            <>
                                <span className="tooltip" data-for={`cumple.${date.toISOString().slice(0, 10)}`} data-tip>
                                    <PastelIcon style={{ color: 'red', fontSize: '1.5em' }} />
                                </span>
                                <ReactTooltip className="contenido-tooltip" effect="solid" id={`cumple.${date.toISOString().slice(0, 10)}`}>
                                    <ul>{cumpleañosMostrar.map(usuario => <li key={usuario.id}>{usuario.nombre}</li>)}</ul>
                                </ReactTooltip>
                            </>
                        )}
                        {vacacionesMostrar.length > 0 && (
                            <>
                                <span className="tooltip" data-for={`vacac.${date.toISOString().slice(0, 10)}`} data-tip>
                                    <VacacionesIcon style={{ color: 'blue', fontSize: '1.5em' }} />
                                </span>
                                <ReactTooltip className="contenido-tooltip" effect="solid" id={`vacac.${date.toISOString().slice(0, 10)}`}>
                                    <ul>{vacacionesMostrar.map(vacacion => <li key={vacacion.fecha}>{vacacion.nombre_usuario}</li>)}</ul>
                                </ReactTooltip>
                            </>
                        )}
                    </div>
                );
            }
        }}
    />
</section>


                <section id="recursos_recientes">
                    <h3>Recursos Recientes</h3>
                    {cargandoRecursos ? <Cargando /> : recursos.map(recurso => <Recurso recurso={recurso} key={recurso.idRecurso} />)}
                </section>
            
            </div>
        </div>
    );
};

export default Dashboard;
