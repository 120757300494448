const MiCuentaIcon = (props) => (
	<svg
		width="1em"
		height="1em"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 21 20"
		{...props}
	>
		<path
			d="M20.718 7.785a.748.748 0 0 0-.373-.495l-2.223-1.232a6.465 6.465 0 0 0-.402-.7l.047-2.54a.774.774 0 0 0-.243-.57A10.458 10.458 0 0 0 13.686.034a.756.756 0 0 0-.616.075l-2.176 1.307c-.27-.009-.532-.009-.803 0L7.915.11A.756.756 0 0 0 7.3.034a10.375 10.375 0 0 0-3.84 2.223.738.738 0 0 0-.243.56l.046 2.54a8.61 8.61 0 0 0-.41.7L.63 7.29a.738.738 0 0 0-.364.495 10.403 10.403 0 0 0 0 4.427.747.747 0 0 0 .373.495l2.223 1.232c.12.242.253.476.401.7l-.046 2.54a.775.775 0 0 0 .243.57 10.46 10.46 0 0 0 3.838 2.214.755.755 0 0 0 .616-.075l2.176-1.308h.803l2.185 1.308a.682.682 0 0 0 .383.112.93.93 0 0 0 .224-.037 10.374 10.374 0 0 0 3.838-2.223.735.735 0 0 0 .243-.56l-.047-2.54c.15-.225.28-.458.411-.7l2.223-1.233a.739.739 0 0 0 .364-.495 10.404 10.404 0 0 0 0-4.427Zm-10.226 6.322a4.109 4.109 0 1 1 0-8.217 4.109 4.109 0 0 1 0 8.217Z"
			fill="#999"
		/>
	</svg>
)

export default MiCuentaIcon