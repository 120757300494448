import "./vacaciones.css";
import Encabezado from "../Encabezado";
import { ToastContainer } from "react-toastify";
import { addDays, compareAsc, isWeekend } from "date-fns";
import CalendarYearly from 'rc-year-calendar';
import { Calendar } from "react-calendar";
import { useNavigate } from "react-router-dom";
import usePaginacion from "../../hooks/usePaginacion";
import Boton from "../Boton/Boton";
import Table from "../Table";
import Cargando from "../Cargando";
import { useEffect, useState } from "react";
import useVacaciones from "../../hooks/useVacaciones";

const VacacionesMapa = ({ title, encabezado, frase, fondo }) => {
    document.title = title;
    const [pagina_users, setPagina_users, porPagina_users] = usePaginacion(8);
    const { getVacaciones, solicitudes: { solicitudes }, getSolicitudesTodas, getUsers, users: { cargandoUsuarios, users } } = useVacaciones();
    const navigate = useNavigate();
    const filasVacias_users = pagina_users > 0 ? Math.max(0, (1 + pagina_users) * porPagina_users - users.length) : 0;

    function mapView() {
        navigate("/vacaciones");
    }

    const [isCalendarVisible, setCalendarVisible] = useState(true);
    const [isCalendarYearlyVisible, setCalendarYearlyVisible] = useState(false);
    const [isUserSelected, setUserSelected] = useState(false);
    const [user_queried, setUserQueried] = useState(null);
    const [calendarYearlyKey, setCalendarYearlyKey] = useState(0);

    const showCalendar = () => {
        setCalendarVisible(true);
        setCalendarYearlyVisible(false);
    };

    const showCalendarYearly = () => {
        setCalendarVisible(false);
        setCalendarYearlyVisible(true);
    };

    const queryMap = (user_id) => {
        setUserSelected(true);
        setUserQueried(user_id);
        setCalendarYearlyKey(prevKey => prevKey + 1);
    };

    const user_active = (event, userId) => {
        const rows = document.querySelectorAll(".hovered-table");

        rows.forEach(row => {
            row.classList.remove("active");
        });

        if (user_queried === userId) {
            setUserQueried(null);
            setUserSelected(false);
            setCalendarYearlyKey(prevKey => prevKey + 1);
        } else {
            event.currentTarget.classList.add("active");
            queryMap(userId);
        }
    };

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    useEffect(() => {
        getVacaciones();
    }, [getVacaciones]);

    useEffect(() => {
        getSolicitudesTodas();
    }, [getSolicitudesTodas]);

    return (
        <div className="layout-contenido">
            <img className="layout-fondo" src={fondo} alt="fondo" />

            <Encabezado titulo={encabezado} frase={frase} />

            <section id="vacaciones">
                <div className="flexed title-button-container">
                    <h3>Administración de Solicitudes de Vacaciones</h3>
                    <Boton texto="Vista de lista" accion={() => mapView()} noFocus />
                </div>

                <div className="calendar-container">
                    <div className="flexed map-button-container">
                        <Boton texto="Calendario Mensual" accion={() => showCalendar()} noFocus />
                        <Boton texto="Calendario Anual" accion={() => showCalendarYearly()} noFocus />
                    </div>
                    {isCalendarVisible && (
                        <Calendar
                            locale="es-ES"
                            tileClassName={({ date, view, activeStartDate }) => {
                                if (view !== 'month') return '';

                                if (isUserSelected) {
                                    if (solicitudes.some(solicitud => solicitud.fechas.split(';').some(fecha => fecha.trim() === date.toISOString().slice(0, 10)) && solicitud.aceptada === 1 && solicitud.id_usuario === Number(user_queried))) return 'aceptada';

                                    if (solicitudes.some(solicitud => solicitud.fechas.split(';').some(fecha => fecha.trim() === date.toISOString().slice(0, 10)) && solicitud.aceptada === 0 && solicitud.id_usuario === Number(user_queried))) return 'solicitada';
                                } else {
                                    if (solicitudes.some(solicitud => solicitud.fechas.split(';').some(fecha => fecha.trim() === date.toISOString().slice(0, 10)) && solicitud.aceptada === 1)) return 'aceptada';

                                    if (solicitudes.some(solicitud => solicitud.fechas.split(';').some(fecha => fecha.trim() === date.toISOString().slice(0, 10)) && solicitud.aceptada === 0)) return 'solicitada';
                                }

                                return '';
                            }}
                            tileDisabled={({ date, view }) => {
                                if (view !== 'month') return false;
                                if (isWeekend(date)) return true;
                                if (compareAsc(date, addDays(new Date(), 0)) === -1) return true;
                                return false;
                            }}
                        />
                    )}
                    {isCalendarYearlyVisible && (
                        <CalendarYearly
                            key={calendarYearlyKey}
                            customDayRenderer={(e, date) => {
                                if (isUserSelected) {
                                    if (solicitudes.some(solicitud => solicitud.fechas.split(';').some(fecha => fecha.trim() === date.toISOString().slice(0, 10)) && solicitud.aceptada === 1 && solicitud.id_usuario === Number(user_queried))) return e.classList.add("aceptada");
                                    if (solicitudes.some(solicitud => solicitud.fechas.split(';').some(fecha => fecha.trim() === date.toISOString().slice(0, 10)) && solicitud.aceptada === 0 && solicitud.id_usuario === Number(user_queried))) return e.classList.add("solicitada");
                                } else {
                                    if (solicitudes.some(solicitud => solicitud.fechas.split(';').some(fecha => fecha.trim() === date.toISOString().slice(0, 10)) && solicitud.aceptada === 1)) return e.classList.add("aceptada");
                                    if (solicitudes.some(solicitud => solicitud.fechas.split(';').some(fecha => fecha.trim() === date.toISOString().slice(0, 10)) && solicitud.aceptada === 0)) return e.classList.add("solicitada");
                                }
                            }}
                        />
                    )}
                </div>
                <div className="table-container">
                    <Table
                        encabezado={['Nombre']}
                        porPagina={porPagina_users}
                        pagina={pagina_users}
                        setPagina={setPagina_users}
                        total={users.length}
                        filasVacias={filasVacias_users}
                        noFixed
                    >
                        {cargandoUsuarios ? (
                            <Cargando tabla />
                        ) : (
                            users
                                .slice(pagina_users * porPagina_users, pagina_users * porPagina_users + porPagina_users)
                                .map(user => (
                                    <tr className={`hovered-table ${user.id === isUserSelected ? "tu-clase-a-toggle" : ""}`} key={user.id} onClick={(event) => user_active(event, user.id)}>
                                        <td className="col-50-l">{user.nombre}</td>
                                    </tr>
                                ))
                        )}
                    </Table>
                </div>

                <ToastContainer />
            </section>
        </div>
    );
};

export default VacacionesMapa;
