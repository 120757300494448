import "./vacaciones.css"
import Encabezado from "../Encabezado"
import VacacionesPersonales from "./VacacionesPersonales"
import { rolAdministrador } from "../../helpers/Variables"
import VacacionesAdministracion from "./VacacionesAdministracion"
import { ToastContainer } from "react-toastify"


const Vacaciones = ({ title, encabezado, frase, fondo }) => {
	document.title = title
	const rol = localStorage.getItem('rol')

	return (
		<div className="layout-contenido">

			<img className="layout-fondo" src={fondo} alt="fondo" />

			<Encabezado titulo={encabezado} frase={frase} />

			<section id="vacaciones">

				{rol === rolAdministrador ? <VacacionesAdministracion /> : <VacacionesPersonales />}

				<ToastContainer />

			</section >

		</div >
	)

}

export default Vacaciones