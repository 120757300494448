import { Field, FieldArray } from "formik"
import Boton from "../Boton/Boton"
import Input from "../Input"


const UsuarioWordPress = ({ usuarios }) => {

	return (
		<FieldArray name="wp">

			{({ remove, unshift }) => (
				<div className="usuarios-wordpress">

					<div style={{ marginBottom: '12px' }}>
						<h4 style={{ marginBottom: '5px' }}>Usuarios WordPress</h4>
						<Boton
							texto="Agregar"
							pequeño
							accion={() => unshift({ usuario: '', contraseña: '' })}
						/>
					</div>

					<div className="sangria">
						{usuarios.map((usuario, i) => (
							<div className="con-boton-wp" key={i}>
								<Field name={`wp.${i}.idAccesoWP`} type="hidden" />
								<div className="dos-columnas">
									<Input
										nombre={`wp.${i}.usuario`}
										etiqueta="Usuario:"
										placeholder="Nombre de Usuario"
									/>
									<Input
										nombre={`wp.${i}.contraseña`}
										etiqueta="Contraseña:"
										placeholder="Contraseña del Usuario"
									/>

								</div>
								{!usuario.idAccesoWP ?
									<Boton
										texto="Eliminar"
										pequeño
										accion={() => remove(i)}
									/>
									: null
								}
							</div>
						))}
					</div>

				</div>
			)}
		</FieldArray>
	)

}

export default UsuarioWordPress