import Modal from "../Modal/Modal"
import { Formik, Form } from "formik"
import TextArea from "../TextArea"


const ModalCancelarSolicitud = ({
	modal,
	setModal,
	cancelar
}) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Cancelar Solicitud"
			idFormulario="cancelar_solicitud"
			textoBoton="Cancelar Solicitud"
		>

			<Formik
				initialValues={{ motivo: '' }}
				onSubmit={cancelar}
			>
				<Form id="cancelar_solicitud">
					<TextArea
						etiqueta="Motivo de Cancelación:*"
						nombre="motivo"
						placeholder="Escriba el motivo"
					/>
				</Form>
			</Formik>

		</Modal>
	)

}

export default ModalCancelarSolicitud