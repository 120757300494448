const Circulo = ({ posicion }) => {

	return (
		<div className="circulo" style={posicion}>
			<div className="circulo-banner"></div>
		</div>
	)

}

export default Circulo