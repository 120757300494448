const AccesosIcon = (props) => (
	<svg
		width="1em"
		height="1em"
		fill="none"
		xmlns="http://www.w4.org/2000/svg"
		viewBox="0 0 20 20"
		{...props}
	>
		<path
			d="M14.389 12c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2m-5.15 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 0 1-4.33 3.56Zm-.25-5.56h-4.68c-.1-.66-.16-1.32-.16-2 0-.68.06-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2Zm-2.34 5.96c-.83-1.2-1.5-2.53-1.91-3.96h3.82c-.41 1.43-1.08 2.76-1.91 3.96ZM6.029 6h-2.92a7.923 7.923 0 0 1 4.32-3.56c-.6 1.11-1.05 2.31-1.4 3.56Zm-2.92 8h2.92c.35 1.25.8 2.45 1.4 3.56A8.008 8.008 0 0 1 3.109 14Zm-.82-2c-.16-.64-.26-1.31-.26-2s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2m4.36-9.97c.83 1.2 1.5 2.54 1.91 3.97h-3.82c.41-1.43 1.08-2.77 1.91-3.97ZM16.949 6h-2.95a15.65 15.65 0 0 0-1.38-3.56c1.84.63 3.37 1.9 4.33 3.56Zm-6.92-6c-5.53 0-10 4.5-10 10a10 10 0 1 0 10-10Z"
			fill="#999"
		/>
	</svg>
)

export default AccesosIcon
