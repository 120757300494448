import './table.css'
import './table.responsive.css'
import TablePaginationUnstyled from '@mui/base/TablePaginationUnstyled'
import { useEffect } from 'react'
import { useRef } from 'react'

const Table = ({ children, encabezado, total, porPagina, pagina, setPagina, filasVacias, noFixed, topSticky }) => {
	const tabla = useRef()

	const cambioPagina = (e, page) => {
		setPagina(page)
	}

	useEffect(() => {
		if (!Array.isArray(children))
			return

		// Calcular ancho del encabezado
		const fila = tabla.current.querySelector('tbody tr')
		if (fila && window.innerWidth < 900) {
			const encabezado = tabla.current.querySelector('thead tr')
			const celdasEncabezado = encabezado.querySelectorAll('th')
			const celdasFila = fila.querySelectorAll('td')
			celdasFila.forEach((celda, i) => {
				if (celda.clientWidth < celdasEncabezado[i].clientWidth)
					celda.style.minWidth = `${celdasEncabezado[i].clientWidth}px`
				else
					celdasEncabezado[i].style.minWidth = `${celda.clientWidth}px`
			})
		}

		return
	}, [children])

	useEffect(() => {
		// Sincronizar Scroll
		const encabezadoHTML = document.querySelector('.tabla thead')
		const cuerpoTabla = document.querySelector('.tabla tbody')
		cuerpoTabla?.addEventListener('scroll', e => {
			encabezadoHTML.scrollLeft = cuerpoTabla.scrollLeft
		})
	}, [])

	return (
		<table ref={tabla} className={noFixed ? 'tabla no-fixed' : 'tabla'}>
			<thead style={{ top: topSticky }}>
				<tr>
					{
						encabezado.map(titulo => <th key={titulo}>{titulo}</th>)
					}
				</tr>
			</thead>
			<tbody>
				{children}

				{filasVacias > 0 && (
					<tr style={{ height: 65 * filasVacias, borderBottom: 'none' }}>
						<td colSpan={5} />
					</tr>
				)}
			</tbody>
			<tfoot>
				<tr>
					<TablePaginationUnstyled
						rowsPerPageOptions={[]}
						rowsPerPage={porPagina}
						count={total}
						page={pagina}
						onPageChange={cambioPagina}
					/>
				</tr>
			</tfoot>
		</table>
	)

}

export default Table