import "./layout.css"
import "./layout.responsive.css"
import Navegacion from "./Navegacion/Navegacion"
import { Outlet, useLocation, Navigate } from "react-router-dom"

const Layout = () => {
	const location = useLocation()

	if (location.pathname === '/')
		return <Navigate to="/dashboard" replace={true} />

	return (
		<div className="app">
			<div className="container">
				<Navegacion />
				<div className="content">
					<Outlet />
				</div>
			</div>
		</div>
	)
}

export default Layout