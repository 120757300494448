import { format, parseISO, isValid } from 'date-fns';
import { es } from 'date-fns/locale';

export const dateAFechaEspañol = date => {
  if (!date) {
    return '';
  }

  try {
    let temporal;

    // Si `date` es un objeto Date, conviértelo a cadena ISO
    if (date instanceof Date) {
      temporal = date.toISOString();
    } else if (typeof date === 'string') {
      temporal = date;
    } else {
      throw new Error('Formato de fecha no soportado');
    }

    // Usa parseISO para manejar el formato ISO
    const parsedDate = parseISO(temporal);

    // Verifica si el objeto Date es válido
    if (!isValid(parsedDate)) {
      throw new Error('Fecha inválida');
    }

    return format(parsedDate, "dd MMMM yyyy", { locale: es });
  } catch (error) {
    console.error('Error al procesar la fecha:', date, error);
    return 'Fecha inválida';
  }
};

const formatter = new Intl.NumberFormat('es-MX', {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2
})

export const monedaFormato = number => {
	return `${formatter.format(number)} MXN`
}
