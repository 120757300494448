export const categorias = [
	'Administración',
	'Ads',
	'AWS',
	'Color',
	'Compresión',
	'CSS',
	'Diseño',
	'Emprendimiento',
	'Figma',
	'Fuentes',
	'Herramientas',
	'HTML',
	'Ilustraciones',
	'Imágenes',
	'Inspiración',
	'Íconos',
	'JavaScript',
	'jQuery',
	'Libros',
	'Marketing Digital',
	'Mockups',
	'Música',
	'PHP',
	'Plugins Diseño',
	'Programación',
	'SEO',
	'Toolset',
	'UX/UI',
	'Ventas',
	'Videos',
	'VR/AR',
	'Web',
	'WordPress'
]