import { useState } from "react";
import { cerrarSesion } from "../helpers/Variables";

const useUsuarios = () => {
    const [usuariosState, setUsuariosState] = useState({ cargandoUsuarios: true, usuarios: [] });
    const getUsuarios = () => {
        fetch(`${process.env.REACT_APP_URI}/usuarios`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => {
                if (response.status === 401) {
                    cerrarSesion();
                }
                return response.json();
            })
            .then(respuesta => setUsuariosState({ cargandoUsuarios: false, usuarios: respuesta }))
            .catch(err => {
                console.error('Error fetching usuarios:', err);
                setUsuariosState({ cargandoUsuarios: false, usuarios: [] });
            });
    };

    return [usuariosState, getUsuarios];
};

export default useUsuarios;
