import "./vacaciones.css"
import Encabezado from "../Encabezado"
import { ToastContainer } from "react-toastify"
import { addDays, compareAsc, isWeekend } from "date-fns"
import CalendarYearly from 'rc-year-calendar';
import { Calendar } from "react-calendar"
import { useNavigate, useParams } from "react-router-dom";
import Boton from "../Boton/Boton"
import Cargando from "../Cargando"
import Table from "../Table"
import { useEffect, useState } from "react"
import { diaSemana } from "../../helpers/Variables"
import { dateAFechaEspañol } from "../../helpers/Formato"
import useVacaciones from "../../hooks/useVacaciones"
import usePaginacion from "../../hooks/usePaginacion"


const VacacionesUserDetails = ({ title, encabezado, frase, fondo }) => {
	document.title = title
	const {
		getSolicitudesTodas,
		getSingleUser,
		user: { cargandoUser, user },
		getSolicitudesPerUser,
		solicitudesUser: { cargandoSolicitudesUser, solicitudesUser }
	} = useVacaciones()
	const navigate = useNavigate();
	const [pagina, setPagina, porPagina] = usePaginacion(4)
	const filasVacias = pagina > 0 ? Math.max(0, (1 + pagina) * porPagina - solicitudesUser.length) : 0
	let { user_id } = useParams();

	const [isCalendarVisible, setCalendarVisible] = useState(true);
	const [isCalendarYearlyVisible, setCalendarYearlyVisible] = useState(false);

	const showCalendar = () => {
		setCalendarVisible(true);
		setCalendarYearlyVisible(false);
	};

	const showCalendarYearly = () => {
		setCalendarVisible(false);
		setCalendarYearlyVisible(true);
	};

	function mapView() {
		navigate("/vacaciones");
	}


	useEffect(() => {
		getSingleUser(user_id)
		// eslint-disable-next-line
		getSolicitudesPerUser(user_id)
		// eslint-disable-next-line
	}, [])

	// eslint-disable-next-line
	useEffect(
		getSolicitudesTodas,
		[])


	return (
		<div className="layout-contenido">

			<img className="layout-fondo" src={fondo} alt="fondo" />

			<Encabezado titulo={encabezado} frase={frase} />

			<section id="vacaciones">


				<Boton texto="Volver" accion={() => mapView()} noFocus />
				<div className="title-button-container">
					<h3>Administración de Solicitudes de Vacaciones </h3>
					<h3 className="user-name">{user.nombre}</h3>
				</div>



				<div className="calendar-container">
					<div className="flexed map-button-container">
						<Boton texto="Calendario Mensual" accion={() => showCalendar()} noFocus />
						<Boton texto="Calendario Anual" accion={() => showCalendarYearly()} noFocus />
					</div>
					{cargandoSolicitudesUser ? '' : isCalendarVisible && (
						<Calendar
							locale="es-ES"
							tileClassName={({ date, view }) => {
								if (view !== 'month') return ''

								if (solicitudesUser.some(solicitud => solicitud.fechas.split(';').some(fecha => fecha.trim() === date.toISOString().slice(0, 10)) && solicitud.aceptada === 1)) return 'aceptada'

								if (solicitudesUser.some(solicitud => solicitud.fechas.split(';').some(fecha => fecha.trim() === date.toISOString().slice(0, 10)) && solicitud.aceptada === 0)) return 'solicitada'

								//if (!isSameMonth(date, activeStartDate)) return

								return ''//`${values.fechas.some(fecha => fecha.fecha === date.toISOString().slice(0, 10)) ? 'festivo' : ''}`
							}}
							tileDisabled={({ date, view }) => {
								if (view !== 'month') return false

								if (isWeekend(date)) return true

								if (compareAsc(date, addDays(new Date(), 0)) === -1) return true

								return false //vacaciones.some(fecha => fecha === date.toISOString().slice(0, 10))
							}}
						/>
					)}
					{cargandoSolicitudesUser ? '' : isCalendarYearlyVisible &&
						<CalendarYearly
							customDayRenderer={(e, date) => {
								if (solicitudesUser.some(solicitud => solicitud.fechas.split(';').some(fecha => fecha.trim() === date.toISOString().slice(0, 10)) && solicitud.aceptada === 1)) return e.classList.add("aceptada")

								if (solicitudesUser.some(solicitud => solicitud.fechas.split(';').some(fecha => fecha.trim() === date.toISOString().slice(0, 10)) && solicitud.aceptada === 0)) return e.classList.add("solicitada")
							}}
						/>

					}
				</div>

				<div class="table-container">
					<table class="days-table">
						<thead>
							<tr>
								<th colspan="3">Días</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>Total</th>
								<th>Usados</th>
								<th>Disponibles</th>
							</tr>
							{cargandoUser ? (
								<Cargando tabla />
							) : (

								<tr key={user.id}>
									<td>{user.vacaciones + user.total_dias ?? 0}</td>
									<td>{user.total_dias ?? 0}</td>
									<td>{user.vacaciones}</td>
								</tr>

							)}
						</tbody>
					</table>
					<Table
						encabezado={['Días']}
						porPagina={porPagina}
						pagina={pagina}
						setPagina={setPagina}
						total={solicitudesUser.length}
						filasVacias={filasVacias}
						noFixed
					>
						{
							cargandoSolicitudesUser ? <Cargando tabla />
								: (solicitudesUser.slice(pagina * porPagina, pagina * porPagina + porPagina)).map(solicitud => (
									<tr key={solicitud.idSolicitudVacaciones}>
										<td>
											{
												solicitud.fechas.split(';').map((fecha, i) => (
													<p key={i}>{`${diaSemana[(new Date(`${fecha} 00:00:00`)).getDay()]}, ${dateAFechaEspañol(fecha)}`}</p>
												))
											}
										</td>
									</tr>
								))
						}
					</Table>
				</div>
				<ToastContainer />

			</section >

		</div >
	)

}

export default VacacionesUserDetails