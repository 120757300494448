import "./cumpleaños.css"
import Encabezado from "../Encabezado"
import { ToastContainer } from "react-toastify"
import { useEffect, useState } from "react"
import Cargando from "../Cargando"
import Table from "../Table"
import { dateAFechaEspañol } from "../../helpers/Formato"
import useUsuarios from "../../hooks/useUsuarios"
import usePaginacion from "../../hooks/usePaginacion"

const Cumpleaños = ({ title, encabezado, frase, fondo }) => {
    document.title = title
    const [{ cargandoUsuarios, usuarios }, getUsuarios] = useUsuarios()
    const [pagina, setPagina, porPagina] = usePaginacion(10)
    const filasVacias = pagina > 0 ? Math.max(0, (1 + pagina) * porPagina - usuarios.length) : 0
    const [isScrolled, setIsScrolled] = useState(false)

    // Función para ordenar usuarios por día y mes
    const ordenarUsuariosPorCumpleaños = (usuarios) => {
        return usuarios.sort((a, b) => {
            const dateA = new Date(a.cumpleaños)
            const dateB = new Date(b.cumpleaños)

            const dayA = dateA.getUTCDate()
            const monthA = dateA.getUTCMonth() + 1
            const dayB = dateB.getUTCDate()
            const monthB = dateB.getUTCMonth() + 1
           

            if (monthA !== monthB) {
                return monthA - monthB
            }
            return dayA - dayB
        })
        
    }
    

    useEffect(() => {
        getUsuarios()
    })

    const handleScroll = (e) => {
        const scrollTop = e.target.scrollTop
        setIsScrolled(scrollTop > 50)
    }

    return (
        <div className="layout-contenido">
            <img className="layout-fondo" src={fondo} alt="fondo" />
            <div className={`encabezado-container ${isScrolled ? 'hidden' : ''}`}>
                <Encabezado titulo={encabezado} frase={frase} />
            </div>

            <section id="cumpleaños">
                <div className="title-button-container">
                    <h3>Administración de Cumpleaños</h3>
                </div>

                <div className="table-container" onScroll={handleScroll}>
                    <Table
                        encabezado={['Nombre', 'Cumpleaños']}
                        porPagina={porPagina}
                        pagina={pagina}
                        setPagina={setPagina}
                        total={usuarios.length}
                        filasVacias={filasVacias}
                        noFixed
                    >
                        {cargandoUsuarios ? (
                            <Cargando tabla />
                        ) : (
                            ordenarUsuariosPorCumpleaños(usuarios)
                                .slice(pagina * porPagina, pagina * porPagina + porPagina)
                                .map(usuario => (
                                    <tr key={usuario.id}>
                                        <td>{usuario.nombre}</td>
                                        <td>{dateAFechaEspañol(usuario.cumpleaños)}</td>
                                        
                                    </tr>
                                ))
                        )}
                        
                    </Table>
                </div>
                <ToastContainer />
            </section>
        </div>
    )
}

export default Cumpleaños
