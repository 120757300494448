import "./credito.css"
import Boton from "../Boton/Boton"
import useCreditosAdministracion from "../../hooks/useCreditosAdministracion"
import { mensajesMutation } from "../../helpers/Variables"
import { useQueryClient } from "@tanstack/react-query"
import Notificacion from "../../helpers/Notificacion"
import { monedaFormato } from "../../helpers/Formato"


const Credito = ({ credito, detalles, abonos, administracion, registrar }) => {

	const queryClient = useQueryClient()
	const { useEliminarCredito } = useCreditosAdministracion()
	const eliminarCredito = useEliminarCredito(mensajesMutation({
		cargando: 'Eliminando Crédito...',
		exito: 'Crédito Eliminado',
		error: 'Ocurrió un Error'
	}))

	const handleEliminarCredito = id => {
		eliminarCredito.mutate(id, {
			onSuccess: () => {
				queryClient.invalidateQueries(['creditos', 'administracion'])
				queryClient.invalidateQueries(['creditos', 'personal'])
			}
		})
	}

	return (
		<div className="credito" >
			<div>
				<div className='cantidad'>
					<span>Usuario:</span>
					<p>{credito.usuario?.nombre}</p>
				</div>
				<div className='cantidad'>
					<span>Monto:</span>
					<p>{monedaFormato(credito.monto + credito.pendiente_inicio)}</p>
				</div>
				<div className='cantidad'>
					<span>Total:</span>
					<p>{monedaFormato(credito.total)}</p>
				</div>
			</div>

			<div className="saldo">
				<span>Saldo:</span>
				<b>{monedaFormato(credito.saldo)}</b>
			</div>

			<div className='botones-acciones'>
				<Boton texto="Detalles" accion={detalles} pequeño noFocus />
				<Boton texto="Abonos" accion={abonos} pequeño noFocus />
				{administracion ? <Boton texto="Abonar" accion={registrar} pequeño noFocus /> : null}
				{administracion ? <Boton texto="Eliminar" accion={() => Notificacion.confirmacion(
					"¿Estás seguro de eliminar este crédito?",
					() => handleEliminarCredito(credito.id)
				)} pequeño noFocus /> : null}
			</div>
		</div>
	)

}

export default Credito