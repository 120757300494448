import { useNavigate } from "react-router-dom"
import AccesosIcon from "../Icons/AccesosIcon"
import CerrarSesionIcon from "../Icons/CerrarSesionIcon"
import CorreosIcon from "../Icons/CorreosIcon"
import DashboardIcon from "../Icons/DashboardIcon"
import MiCuentaIcon from "../Icons/MiCuentaIcon"
import RecursosIcon from "../Icons/RecursosIcon"
import ServiciosIcon from "../Icons/ServiciosIcon"
import VacacionesIcon from "../Icons/VacacionesIcon"
import PastelIcon from "../Icons/PastelIcon"
import BotonNav from "./BotonNav"
import { rolAdministrador } from "../../helpers/Variables"
import { ocultarMenu } from "../../helpers/ToggleNavegacion"
import CrediSelfish from "../Icons/CrediSelfish"

const menus = [
	{ texto: 'dashboard', link: '/dashboard', icono: <DashboardIcon />, administrador: false },
	{ texto: 'recursos', link: '/recursos', icono: <RecursosIcon />, administrador: false },
	{ texto: 'accesos', link: '/accesos', icono: <AccesosIcon />, administrador: false },
	{ texto: 'suscripciones', link: '/suscripciones', icono: <ServiciosIcon />, administrador: false },
	{ texto: 'vacaciones', link: '/vacaciones', icono: <VacacionesIcon />, administrador: false },
	{ texto: 'cumpleaños', link: '/cumpleanos', icono: <PastelIcon />, administrador: false },
	{ texto: 'crediselfish', link: '/crediselfish', icono: <CrediSelfish />, administrador: false },
	{ texto: 'anuncios', link: '/anuncios', icono: <MiCuentaIcon />, administrador: true },
	{ texto: 'correos', link: '/correosInternos', icono: <CorreosIcon />, administrador: true },
	{ texto: 'cerrar sesión', link: '/login', icono: <CerrarSesionIcon />, administrador: false }
]

const estilos = {
	display: 'flex',
	flexFlow: 'column nowrap',
	gap: '12px',
	width: '100%'
}

const BotonesNav = () => {
	const navigate = useNavigate()
	const rol = localStorage.getItem('rol')
	const menusFiltrados = rol !== rolAdministrador ? menus.filter(boton => !boton.administrador) : menus

	return (
		<div style={estilos}>
			{
				menusFiltrados.map(boton => (
					<BotonNav
						texto={boton.texto}
						link={boton.link}
						icono={boton.icono}
						accion={boton.texto === 'cerrar sesión' ?
							() => { localStorage.clear(); navigate('login', { replace: true }) }
							: ocultarMenu
						}
						key={boton.texto}
					/>
				))
			}
		</div>
	)

}

export default BotonesNav