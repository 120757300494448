import Modal from "../Modal/Modal"
import Input from "../Input"
import TextArea from "../TextArea"
import { Formik, Form } from "formik"
import File from "../File"
import Select from "../Select"
import { dias, meses } from "../../helpers/Variables"

const ModalModificarAnuncio = ({ modal, setModal, anuncio, modificar }) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo={`Modificar ${anuncio.festivo === 1 ? 'Día Festivo' : 'Anuncio'}`}
			idFormulario="modificar_anuncio"
			textoBoton="Modificar"
		>
			<Formik
				initialValues={{
					titulo: anuncio.titulo,
					descripcion: anuncio.descripcion || '',
					"fecha-inicio": anuncio.fechaInicio || '',
					"fecha-fin": anuncio.fechaFin || '',
					imagen: anuncio.imagen || '',
					imagenFILE: '',
					enlace: anuncio.enlace || '',
					dia: anuncio.dia || '',
					mes: anuncio.mes || '',
					festivo: anuncio.festivo
				}}
				onSubmit={valores => modificar(valores, anuncio.idAnuncio)}
			>
				{
					({ setFieldValue }) => (
						<Form id='modificar_anuncio'>
							<Input
								nombre="titulo"
								placeholder="Título del Anuncio"
								etiqueta="Título:*"
								required
							/>
							{anuncio.festivo === 1 ? <div>
								<Select
									nombre="mes"
									etiqueta="Mes:"
								>
									{
										meses.map((mes, i) => (
											<option key={i} value={i + 1}>{mes}</option>
										))
									}
								</Select>
								<Select
									nombre="dia"
									etiqueta="Día:"
								>
									{
										dias.map(numero => (
											<option key={numero} value={numero}>{numero}</option>
										))
									}
								</Select></div>
								:
								<div>
									<TextArea
										nombre="descripcion"
										etiqueta="Descripción"
										placeholder="Descripción del Anuncio"
									/>
									<Input
										nombre="fecha-inicio"
										etiqueta="Fecha Inicial:"
										tipo="date"
									/>
									<Input
										nombre="fecha-fin"
										etiqueta="Fecha Final:"
										tipo="date"
									/>
									<Input
										nombre="imagen"
										placeholder="URL de la Imágen del Anuncio"
										etiqueta="Imágen:"
									/>
									<File
										nombre="imagenFILE"
										etiqueta="ó Arrastra o Selecciona la Imágen"
										setFieldValue={setFieldValue}
									/>
									<Input
										nombre="enlace"
										placeholder="Enlace del Anuncio"
										etiqueta="Enlace:"
										tipo="url"
									/>
								</div>}
						</Form>
					)
				}
			</Formik>
		</Modal>
	)

}

export default ModalModificarAnuncio