import { useState } from "react"
import { cerrarSesion } from "../helpers/Variables"

const useRecursos = () => {

	const [recursos, setRecursos] = useState({ cargandoRecursos: true, recursos: [] })

	// Buscar recursos con filtros
	const buscarRecursos = (categoria, titulo) => {
		setRecursos({ cargandoRecursos: true, recursos: recursos.recursos })
		let url = ''
		if (categoria === 'inicio') {
			if (titulo === '')
				return setRecursos({ cargandoRecursos: false, recursos: [] })
			url = `${process.env.REACT_APP_URI}/recursos/?busqueda=${titulo}`
		} else
			url = `${process.env.REACT_APP_URI}/recursos/categoria/${categoria}?busqueda=${titulo}`

		fetch(url, {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
			.then(response => {
				if (response.status === 401)
					cerrarSesion()
				return response.json()
			})
			.then(respuesta => setRecursos({ cargandoRecursos: false, recursos: respuesta }))
			.catch(err => console.error(err))
	}

	// Crear un nuevo recurso
	const crearRecurso = async datos => {
		const response = await fetch(`${process.env.REACT_APP_URI}/recursos/crear`, {
			method: 'POST',
			headers: {
				'content-type': 'application/json',
				'accept': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({
				titulo: datos.titulo.trim(),
				descripcion: datos.descripcion.trim() === '' ? null : datos.descripcion.trim(),
				enlace: datos.enlace.trim(),
				imagen: datos.imagen.trim() === '' ? null : datos.imagen.trim(),
				categoria: datos.categoria.trim()
			})
		})
		if (response.status === 401)
			cerrarSesion()
		return await response.json()
	}

	const modificarRecurso = async (datos, id) => {
		const response = await fetch(`${process.env.REACT_APP_URI}/recursos/modificar/${id}`, {
			method: 'PUT',
			headers: {
				'content-type': 'application/json',
				'accept': 'application/json',
				authorization: `Bearer ${localStorage.getItem('token')}`
			},
			body: JSON.stringify({
				titulo: datos.titulo.trim(),
				descripcion: datos.descripcion.trim() === '' ? null : datos.descripcion.trim(),
				enlace: datos.enlace.trim(),
				imagen: datos.imagen.trim() === '' ? null : datos.imagen.trim(),
				categoria: datos.categoria.trim()
			})
		})
		if (response.status === 401)
			cerrarSesion()
		return await response.json()
	}

	const eliminarRecurso = async id => {
		const response = await fetch(`${process.env.REACT_APP_URI}/recursos/eliminar/${id}`, {
			method: 'DELETE',
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
		if (response.status === 401)
			cerrarSesion()
		return await response.json()
	}

	// Obtener los recursos recientes
	const obtenerRecientes = () => {
		fetch(`${process.env.REACT_APP_URI}/recursos/recientes`, {
			headers: {
				authorization: `Bearer ${localStorage.getItem('token')}`
			}
		})
			.then(response => {
				if (response.status === 401)
					cerrarSesion()
				return response.json()
			})
			.then(respuesta => setRecursos({ cargandoRecursos: false, recursos: respuesta }))
			.catch(error => console.error(error))
	}

	return [recursos, setRecursos, buscarRecursos, crearRecurso, obtenerRecientes, modificarRecurso, eliminarRecurso]

}

export default useRecursos