const OjoCerrado = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		fill="none"
		viewBox="2 0.5 16 17"
		{...props}
	>
		<path
			d="M2.5 8.718A11.13 11.13 0 0 0 5 10.955m12.5-2.237a11.13 11.13 0 0 1-2.5 2.237m-6.667 1.353-.416 1.826m.416-1.825a8.827 8.827 0 0 0 3.334 0m-3.334 0A9.397 9.397 0 0 1 5 10.955m6.667 1.353.416 1.826m-.416-1.825A9.397 9.397 0 0 0 15 10.955m0 0 1.25 1.513M5 10.955l-1.25 1.513"
			stroke="#999"
			strokeWidth={2}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default OjoCerrado
