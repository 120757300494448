import Input from "../Input"
import Modal from "../Modal/Modal"
import { Formik, Form } from "formik"

const ModalModificarServicio = ({ modal, setModal, servicio, modificar }) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Modificar Servicio"
			idFormulario="modificar_servicio"
			textoBoton="Modificar"
		>
			<Formik
				initialValues={{
					enlace: servicio.enlace || '',
					usuario: servicio.usuario,
					contraseña: servicio.contraseña
				}}
				onSubmit={valores => modificar(valores, servicio.idAccesoPlataforma)}
			>
				<Form id='modificar_servicio'>
					<Input
						nombre="enlace"
						etiqueta="Enlace:*"
						placeholder="Enlace a la Plataforma"
						tipo="url"
						required
					/>
					<Input
						nombre="usuario"
						etiqueta="Usuario:*"
						placeholder="Usuario o Correo de la Plataforma"
					/>
					<Input
						nombre="contraseña"
						placeholder="Contraseña de la Plataforma"
						etiqueta="Contraseña:*"
					/>
				</Form>
			</Formik>
		</Modal>
	)

}

export default ModalModificarServicio