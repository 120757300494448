import './accesos.css'
import './accesos.responsive.css'
import { useState } from 'react'
import Table from '../Table'
import BusquedaAccesos from './BusquedaAccesos'
import { ToastContainer } from "react-toastify"
import usePaginacion from '../../hooks/usePaginacion'
import Boton from '../Boton/Boton'
import useAccesos from '../../hooks/useAccesos'
import ModalAgregarAccesos from './ModalAgregarAccesos'
import BotonSuperior from '../Boton/BotonSuperior'
import ModalModificarAcceso from './ModalModificarAcceso'
import ModalModificarAccesoCPN from './ModalModificarAccesoCPN'
import ModalModificarAccesoDB from './ModalModificarAccesoDB'
import Encabezado from '../Encabezado'
import ModalModificarPagina from './ModalModificarPagina'
import Cargando from '../Cargando'
import { useQueryClient } from '@tanstack/react-query'
import { mensajesMutation } from '../../helpers/Variables'
import Notificacion from '../../helpers/Notificacion'


const Accesos = ({ title, encabezado, frase, fondo }) => {
	document.title = title
	const queryClient = useQueryClient()
	const [buscar, setBuscar] = useState('')
	const { useGetAccesos,
		useCrearAcceso,
		useModificarAccesosWP,
		useModificarCPanel,
		useModificarDB,
		useModificarPagina,
		useEliminarAccesos
	} = useAccesos()
	const accesosQuery = useGetAccesos(buscar)

	const modificarAccesosWP = useModificarAccesosWP(mensajesMutation({
		cargando: 'Modificando Acceso...',
		exito: 'Acceso Modificado',
		error: 'Ocurrió un Error'
	}))

	const agregarAccesos = useCrearAcceso(mensajesMutation({
		cargando: 'Creando Accesos...',
		exito: 'Accesos Publicado',
		error: 'Ocurrió un Error'
	}))

	const modificarPagina = useModificarPagina(mensajesMutation({
		cargando: 'Modificando Página...',
		exito: 'Página Modificada',
		error: 'Ocurrió un Error'
	}))

	const modificarCPanel = useModificarCPanel(mensajesMutation({
		cargando: 'Modificando Acceso...',
		exito: 'Acceso Modificado',
		error: 'Ocurrió un Error'
	}))

	const modificarDB = useModificarDB(mensajesMutation({
		cargando: 'Modificando Acceso...',
		exito: 'Acceso Modificado',
		error: 'Ocurrió un Error'
	}))

	const eliminarAccesos = useEliminarAccesos(mensajesMutation({
		cargando: 'Eliminando Accesos...',
		exito: 'Accesos Eliminado',
		error: 'Ocurrió un Error'
	}))

	const [modalAgregar, setModalAgregar] = useState(false)
	const [modalModificar, setModalModificar] = useState(false)
	const [modalModificarCPN, setModalModificarCPN] = useState(false)
	const [modalModificarDB, setModalModificarDB] = useState(false)
	const [modalModificarPag, setModalModificarPag] = useState(false)
	const [accesoModificar, setAccesoModificar] = useState({})
	const [pagina, setPagina, porPagina] = usePaginacion(25)
	const filasVacias = pagina > 0 ? Math.max(0, (1 + pagina) * porPagina - accesosQuery.data?.length) : 0

	// eslint-disable-next-line

	const handleCrearAccesos = (valores) => {
		agregarAccesos.mutate(valores, {
			onSuccess: () => {
				queryClient.invalidateQueries(['clientes', 'accesos'])
				setModalAgregar(false)
			}
		})
	}

	const handleModificarWP = (datos, id) => {
		modificarAccesosWP.mutate({ datos, id }, {
			onSuccess: () => {
				queryClient.invalidateQueries(['clientes', 'accesos'])
				setModalModificar(false)
			}
		})
	}

	const handleModificarCPanel = (datos, id) => {
		modificarCPanel.mutate({ datos, id }, {
			onSuccess: () => {
				queryClient.invalidateQueries(['clientes', 'accesos'])
				setModalModificarCPN(false)
			}
		})
	}

	const handleModificarDB = (datos, id) => {
		modificarDB.mutate({ datos, id }, {
			onSuccess: () => {
				queryClient.invalidateQueries(['clientes', 'accesos'])
				setModalModificarDB(false)
			}
		})
	}

	const handleModificarPagina = (datos, id) => {
		modificarPagina.mutate({ datos, id }, {
			onSuccess: () => {
				queryClient.invalidateQueries(['clientes', 'accesos'])
				setModalModificarPag(false)
			}
		})
	}

	const handleEliminarAccesos = id => {
		eliminarAccesos.mutate(id, {
			onSuccess: () => {
				queryClient.invalidateQueries(['clientes', 'accesos'])
				queryClient.refetchQueries(['clientes', 'accesos'], { type: 'active' })
			}
		})
	}

	return (
		<div className='layout-contenido accesos-contenido'>

			<img className="layout-fondo" src={fondo} alt="fondo" />

			<Encabezado titulo={encabezado} frase={frase} />

			<BotonSuperior texto="Agregar Accesos" accion={() => setModalAgregar(true)} />

			<BusquedaAccesos buscar={({ pagina }) => { setPagina(0); setBuscar(pagina) }} />

			<Table
				encabezado={['Página', 'cPanel', 'WordPress', 'Base de Datos', 'Modificar']}
				porPagina={porPagina}
				pagina={pagina}
				setPagina={setPagina}
				total={accesosQuery.data?.length || 0}
				filasVacias={filasVacias}
			>
				{
					accesosQuery.isLoading ? <Cargando tabla />
						: (accesosQuery.data?.slice(pagina * porPagina, pagina * porPagina + porPagina)).map(acceso => (
							<tr key={acceso.id}>
								<td>{acceso.pagina}</td>
								<td>
									<div className='accesos'>
										<p><span>Link:</span>{acceso.cpanel.enlace}</p>
										<p><span>Usuario:</span>{acceso.cpanel.usuario}</p>
										<p><span>Contraseña:</span>{acceso.cpanel.contraseña}</p>
									</div>
								</td>
								<td>{
									acceso.wp.map(awp =>
										<div className='accesos' key={awp.id}>
											<p><span>Usuario:</span>{awp.usuario}</p>
											<p><span>Contraseña:</span>{awp.contraseña}</p>
										</div>
									)
								}</td>
								<td>
									<div className='accesos'>
										<p><span>Nombre:</span>{acceso.db.nombre}</p>
										<p><span>Usuario:</span>{acceso.db.usuario}</p>
										<p><span>Contraseña:</span>{acceso.db.contraseña}</p>
									</div>
								</td>
								<td>
									<div className='botones-acciones'>
										<Boton texto="Página" pequeño noFocus accion={() => { setAccesoModificar(acceso); setModalModificarPag(true) }} />
										<Boton texto="cPanel" pequeño noFocus accion={() => { setAccesoModificar(acceso.cpanel); setModalModificarCPN(true) }} />
										<Boton texto="WordPress" pequeño noFocus accion={() => { setAccesoModificar(acceso); setModalModificar(true) }} />
										<Boton texto="BD" pequeño noFocus accion={() => { setAccesoModificar(acceso.db); setModalModificarDB(true) }} />
										<Boton texto="Eliminar" pequeño noFocus accion={() => Notificacion.confirmacion(
											"¿Estás seguro de eliminar el acceso?"
											, () => handleEliminarAccesos(acceso.id)
										)} />
									</div>
								</td>
							</tr>
						))
				}
			</Table>

			<ModalAgregarAccesos modal={modalAgregar} setModal={setModalAgregar} crear={handleCrearAccesos} />
			<ModalModificarAccesoCPN modal={modalModificarCPN} setModal={setModalModificarCPN} acceso={accesoModificar} modificar={handleModificarCPanel} />
			<ModalModificarAcceso modal={modalModificar} setModal={setModalModificar} usuarios={accesoModificar} modificar={handleModificarWP} />
			<ModalModificarAccesoDB modal={modalModificarDB} setModal={setModalModificarDB} acceso={accesoModificar} modificar={handleModificarDB} />
			<ModalModificarPagina modal={modalModificarPag} setModal={setModalModificarPag} acceso={accesoModificar} modificar={handleModificarPagina} />
			<ToastContainer />
		</div>
	)

}

export default Accesos