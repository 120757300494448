export const ocultarMenu = () => {
	const nav = document.querySelector('.app .navegacion')
	document.querySelector('.content').style.pointerEvents = 'all'
	nav.classList.remove('abierto')
}

export const mostrarMenu = () => {
	const nav = document.querySelector('.app .navegacion')
	document.querySelector('.content').style.pointerEvents = 'none'
	nav.classList.add('abierto')
}
