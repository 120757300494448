import './creditos.css'
import './creditos.responsive.css'
import Encabezado from "../Encabezado"
import CreditosAdministracion from './CreditosAdministracion'
import CreditosPersonales from './CreditosPersonales'
import { rolAdministrador } from '../../helpers/Variables'

const Creditos = ({ title, encabezado, frase, fondo }) => {
	document.title = title
	const rol = localStorage.getItem('rol')

	return (
		<div className="layout-contenido">
			<img className="layout-fondo" src={fondo} alt="fondo" />
			<Encabezado titulo={encabezado} frase={frase} />

			<div id="creditos">

				{rol === rolAdministrador ? <CreditosAdministracion /> : null}

				<CreditosPersonales />

			</div>

		</div>
	)

}

export default Creditos