import Modal from "../Modal/Modal"
import TextArea from "../TextArea"
import { Formik, Form } from "formik"

const ModalRechazarVacaciones = ({ modal, setModal, rechazar , user_rejected_id}) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Rechazar Vacaciones"
			idFormulario="rechazar_vacaciones"
			textoBoton="Rechazar"
		>
			<Formik
				initialValues={{
					motivo: '',
					user_id_queried : user_rejected_id,
				}}
				onSubmit={rechazar}
			>
				<Form id="rechazar_vacaciones">
					<TextArea
						nombre="motivo"
						etiqueta="Motivo"
						placeholder="Motivo del Rechazo"
					/>
				</Form>
			</Formik>
		</Modal>
	)

}

export default ModalRechazarVacaciones