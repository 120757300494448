import Circulo from "./Circulo";
import Tarjeta from "./Tarjeta";
import LoginImg from "./LoginImg";
import Formulario from "./Formulario";
import "./login.css"
import "./login.responsive.css"

const Login = ({ title }) => {
	document.title = title
	return (
		<div className="login">
			<Circulo posicion={{ top: '-55%', left: '60%' }} />
			<Circulo posicion={{ top: '55%', left: '-15%' }} />
			<Tarjeta>
				<LoginImg />
				<Formulario />
			</Tarjeta>
		</div>
	)
}

export default Login