import Busqueda from "../Busqueda/Busqueda"
import Input from "../Input"

const BusquedaCorreos = ({ buscar }) => {

	return (
		<Busqueda valores={{ correo: '' }} submit={buscar}>
			<Input
				nombre="correo"
				etiqueta="Correo:"
				placeholder="Correo del Usuario"
			/>
		</Busqueda>
	)

}

export default BusquedaCorreos