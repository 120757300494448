import { useState } from "react"
import "./file.css"

const File = ({ setFieldValue, etiqueta, nombre }) => {
	const [nombreArchivo, setNombreArchivo] = useState(etiqueta)

	return (
		<div className="file">
			<label>{nombreArchivo}</label>
			<input
				name={nombre}
				type="file"
				accept="image/*"
				onChange={(e) => {
					const [imagen] = e.currentTarget.files
					setNombreArchivo(imagen?.name)
					setFieldValue("imagenFILE", imagen)
				}}
				onDragOver={e => { e.currentTarget.parentElement.classList.add('drop') }}
				onDragLeave={e => { e.currentTarget.parentElement.classList.remove('drop') }}
			/>
		</div>
	)

}

export default File