const LinkIcon = (props) => (
	<svg
		width="1em"
		height="1em"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		{...props}
	>
		<path
			d="M5.856 9.171c1.328-1.327 3.644-1.327 4.972 0l.828.829 1.657-1.657-.828-.829a5.818 5.818 0 0 0-4.143-1.717 5.82 5.82 0 0 0-4.143 1.717L1.712 10a5.869 5.869 0 0 0 0 8.287A5.839 5.839 0 0 0 5.855 20 5.838 5.838 0 0 0 10 18.287l.829-.829-1.658-1.657-.828.829a3.524 3.524 0 0 1-4.973 0 3.522 3.522 0 0 1 0-4.973l2.487-2.486Z"
			fill="#999"
		/>
		<path
			d="m9.999 1.713-.829.829 1.658 1.657.828-.829a3.524 3.524 0 0 1 4.973 0 3.522 3.522 0 0 1 0 4.973l-2.487 2.486c-1.328 1.327-3.644 1.327-4.972 0L8.342 10l-1.657 1.657.828.829a5.819 5.819 0 0 0 4.143 1.716c1.566 0 3.038-.61 4.143-1.716L18.286 10a5.869 5.869 0 0 0 0-8.287 5.867 5.867 0 0 0-8.287 0Z"
			fill="#999"
		/>
	</svg>
)

export default LinkIcon