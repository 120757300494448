const BotonSuperior = ({ children, texto, accion }) => {

	return (
		<div className="boton-superior">
			{children}
			<button className='boton no-focus' onClick={accion} >
				{texto}
			</button>
		</div>
	)

}

export default BotonSuperior