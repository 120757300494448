import { ocultarMenu } from "../../helpers/ToggleNavegacion"
import CloseIcon from "../Icons/CloseIcon"
import BotonesNav from "./BotonesNav"
import "./navegacion.css"
import "./navegacion.responsive.css"

const Navegacion = () => {

	const nombre = localStorage.getItem('nombre')
	const rol = localStorage.getItem('rol')

	return (
		<div className="navegacion">
			<div className="menu-movil-cerrar" onClick={ocultarMenu}>
				<CloseIcon />
			</div>
			<img src="/assets/Selfish-logo.svg" alt="Logo" width="90px" />
			<div className="perfil">
				<p>{nombre}</p>
				<span>{rol}</span>
			</div>
			<BotonesNav />
		</div>
	)
}

export default Navegacion