import { useEffect, useRef, useState } from "react"
import Notificacion from "../../helpers/Notificacion"
import useRecursos from "../../hooks/useRecursos"
import BotonSuperior from "../Boton/BotonSuperior"
import BusquedaRecursos from "./BusquedaRecursos"
import Categorias from "./Categorias"
import ModalAgregarRecurso from "./ModalAgregarRecurso"
import Recurso from "./Recurso"
import { ToastContainer } from "react-toastify"
import './recursos.css'
import './recursos.responsive.css'
import Encabezado from "../Encabezado"
import Boton from "../Boton/Boton"
import Cargando from "../Cargando"
import ModalModificarRecurso from "./ModalModificarRecurso.js"

const Recursos = ({ title, encabezado, frase, fondo }) => {
	document.title = title

	const [{ cargandoRecursos, recursos }, setRecursos, buscarRecursos, crearRecurso, , modificarRecurso, eliminarRecurso] = useRecursos()
	const [categoria, setCategoria] = useState('inicio')
	const [modal, setModal] = useState(false)
	const formulario = useRef()
	const [recursoModificar, setRecursoModificar] = useState({})
	const [modalModificar, setModalModificar] = useState(false)

	const limpiarRecursos = () => {
		formulario.current?.resetForm()
		setRecursos({ cargandoRecursos: false, recursos: [] })
		setCategoria('inicio')
	}

	// eslint-disable-next-line
	useEffect(() => buscarRecursos(categoria, ''), [categoria])

	const crear = datos => {
		const idNotificacion = Notificacion.cargando('Agregando Recurso...')

		crearRecurso(datos)
			.then(respuesta => {
				if (respuesta === 1) {
					Notificacion.exito(idNotificacion, 'Recurso Agregado')
					document.querySelector('.busqueda input[type="submit"]').click()
					setModal(false)
				} else
					Notificacion.error(idNotificacion, 'Ocurrió un Error')

			})
			.catch(err => console.error(err))
	}

	const modificar = (datos, id) => {
		const idNotificacion = Notificacion.cargando('Modificando Recurso...')

		modificarRecurso(datos, id)
			.then(respuesta => {
				if (respuesta === 1) {
					Notificacion.exito(idNotificacion, 'Recurso Modificado')
					document.querySelector('.busqueda input[type="submit"]').click()
					setModalModificar(false)
				} else
					Notificacion.error(idNotificacion, 'Ocurrió un Error')

			})
			.catch(err => console.error(err))
	}

	const eliminar = id => {
		const idNotificacion = Notificacion.cargando('Eliminando Recurso...')

		eliminarRecurso(id)
			.then(respuesta => {
				if (respuesta === 1) {
					Notificacion.exito(idNotificacion, 'Recurso Eliminado')
					document.querySelector('.busqueda input[type="submit"]').click()
					setModalModificar(false)
				} else
					Notificacion.error(idNotificacion, 'Ocurrió un Error')

			})
			.catch(err => console.error(err))
	}

	return (
		<div style={{ paddingBottom: '20px' }} className="layout-contenido">

			<img className="layout-fondo" src={fondo} alt="fondo" />

			<Encabezado titulo={encabezado} frase={frase} />

			<BotonSuperior texto="Agregar Recurso" accion={() => setModal(true)} />

			<BusquedaRecursos titulo="" buscar={datos => buscarRecursos(categoria, datos.titulo)} formulario={formulario} />

			{
				categoria !== 'inicio' || recursos.length > 0 ?
					<div>
						<Boton accion={limpiarRecursos} noFocus texto="Regresar" />
						<div id="recursos">
							{
								cargandoRecursos ? <Cargando />
									: recursos.map(recurso => <Recurso
										modificar={() => { setRecursoModificar(recurso); setModalModificar(true) }}
										recurso={recurso} key={recurso.idRecurso}
									/>)
							}
						</div>

					</div>
					: <Categorias setCategoria={setCategoria} />
			}

			<ModalAgregarRecurso modal={modal} setModal={setModal} crear={crear} />

			<ModalModificarRecurso modal={modalModificar} setModal={setModalModificar} recurso={recursoModificar} modificar={modificar} eliminar={eliminar} />

			<ToastContainer />
		</div>
	)

}

export default Recursos