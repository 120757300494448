import Modal from "../Modal/Modal"
import { Formik, Form } from "formik"
import Input from "../Input"


const ModalAceptarSolicitud = ({
	modal,
	setModal,
	aceptar
}) => {

	return (
		<Modal
			open={modal}
			cerrar={() => setModal(false)}
			titulo="Aceptar Solicitud de Crédito"
			idFormulario="aceptar_solicitud"
			textoBoton="Aceptar Crédito"
		>
			<Formik
				initialValues={{ interes: '' }}
				onSubmit={aceptar}
			>
				<Form id="aceptar_solicitud">
					<Input
						nombre="interes"
						placeholder="Interés para el crédito"
						etiqueta="Interés (%):*"
						tipo="number"
						required
						min="0"
						step="0.1"
					/>
				</Form>
			</Formik>

		</Modal>
	)

}

export default ModalAceptarSolicitud